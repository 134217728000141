import { Box, Button, Flex, FlexProps, MantineSize, Menu, Text, UnstyledButton } from "@mantine/core"
import { SafetyControlStatus, SafetyControlStatusEnum } from "@soar/shared/types"
import { SafetyControlStatusConfig } from "@soar/shared/utils"
import { ReactElement, useMemo } from "react"
import { MaterialSymbol } from "../icons"

const allStatuses = [
  SafetyControlStatusEnum.enum.drafted,
  SafetyControlStatusEnum.enum.under_review,
  SafetyControlStatusEnum.enum.operational,
  SafetyControlStatusEnum.enum.retired,
]

const SafetyControlStatusIcons: Record<SafetyControlStatus, ReactElement> = {
  [SafetyControlStatusEnum.enum.drafted]: <MaterialSymbol name="circle" />,
  [SafetyControlStatusEnum.enum.under_review]: <MaterialSymbol name="search_insights" fill />,
  [SafetyControlStatusEnum.enum.operational]: <MaterialSymbol name="progress_activity" />,
  [SafetyControlStatusEnum.enum.retired]: <MaterialSymbol name="cancel" />,
}

export function SafetyControlStatusIcon({
  status,
  size,
}: {
  status: SafetyControlStatus
  size?: MantineSize
}) {
  const currentConfig = SafetyControlStatusConfig[status]
  return (
    <Flex align="center" c={currentConfig.color} fz={size}>
      {SafetyControlStatusIcons[status]}
    </Flex>
  )
}

export function SafetyControlStatusDisplay({
  status,
  justify,
  size,
}: {
  status: SafetyControlStatus
  justify?: FlexProps["justify"]
  size?: MantineSize
}) {
  const currentConfig = SafetyControlStatusConfig[status]
  return (
    <Flex fz={size} display="flex" align="center" gap={5} justify={justify}>
      <Flex align="center" c={currentConfig.color}>
        {SafetyControlStatusIcons[status]}
      </Flex>
      <Text truncate>{currentConfig.label}</Text>
    </Flex>
  )
}

export function SafetyControlStatusMenu({
  status,
  onChange = () => {},
}: {
  status: SafetyControlStatus
  onChange?: (status: SafetyControlStatus) => void
}) {
  const { otherStatuses, currentConfig } = useMemo(() => {
    const otherStatuses = allStatuses.filter((statusFilter) => statusFilter !== status)
    const currentConfig = SafetyControlStatusConfig[status]

    return {
      otherStatuses,
      currentConfig,
    }
  }, [status])

  return (
    <Menu>
      <Menu.Target>
        <Button variant="subtle" fz="md">
          <Box fw={600} c="black">
            <SafetyControlStatusDisplay status={status} />
          </Box>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {otherStatuses.map((statusInLoop) => {
          return (
            <Menu.Item key={statusInLoop} onClick={() => onChange(statusInLoop)}>
              <Box fw={500}>
                <SafetyControlStatusDisplay status={statusInLoop} />
              </Box>
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export function SafetyControlStatusBadge() {}
