import { z } from "zod"
import { PartialUserSchema } from "./User"
import { BaseComparatorOperationSchema, SortEnumSchema } from "./utility"

export const SafetyReportStatusEnum = z.enum(["created", "underReview", "inProgress", "resolved"])

export const OldSafetyReportFactorTypeEnum = z.enum([
  "miscommunication",
  "inattention",
  "failure_of_process",
  "missing_process",
  "pilot_error",
  "aircraft_malfunction",
  "weather",
  "terrain",
  "aerodrome",
  "passenger",
  "cargo",
])

export const SafetyReportFactorTypeEnum = z.enum([
  "weather_condition",
  "pilot_error",
  "mechanical_failure",
  "atc_issue",
  "fuel_management",
  "runway_incursion",
  "bird_strike",
  "procedure_deviation",
  "emergency_readiness",
  "crew_fatigue",
  "training_gap",
  "communication_failure",
  "security_threat",
  "cargo_handling",
  "navigation_error",
  "ground_incident",
  "regulatory_compliance",
  "passenger_safety",
  "medical_emergency",
  "maintenance_issue",

  // Flight
  "wildlife",
  "weather",
  "turbulence",
  "system_malfunction",
  "flight_planning",
  "congestion",
  "human_error",
  "fatigue",
  "navigation",
  "maintenance",
  "foreign_object_debris",
  "weight_and_balance",
  "charts_and_data",
  "communication",
  "situational_awareness",
  // "fuel_management",
  "uncontrolled_airspace",
  "drones",
  "distractions",
  "visibility",

  // Ground
  "hazmat",
  //"fod_(foreign_object_debris)",
  "fueling",
  "signage",
  // "congestion",
  "ground_training",
  "lighting",
  "equipment_malfunction",
  "slippery_surfaces",
  "obstructions",
  "procedures",
  "deicing",
  //"communication",
  "towing",
  "ladders_and_stands",
  "exits_and_access",
  "unauthorized_personnel",
  "surface_damage",
  "chocks_and_brakes",
  "loading",
])

export const SafetyReportEntityTypeEnum = z.enum([
  "aircraft",
  "aerodrome",
  "pilot",
  "runway",
  "vendor",
  "mechanic",
  "staff",
  "other_personnel",
])

export const FlightStageEnum = z.enum([
  "pre_taxi",
  "taxi_out",
  "takeoff",
  "climb",
  "cruise",
  "descent",
  "approach",
  "landing",
  "taxi_in",
  "shutdown",
])

export const SafetyReportEntitySchema = z.object({
  id: z.string().ulid(),
  type: SafetyReportEntityTypeEnum,
  identifier: z.string(),
  scope: z.string().nullish(),
})

export const SafetyReportTypeEnum = z.enum(["flight", "ground"])

export const SafetyReportSchema = z.object({
  id: z.string().ulid(),
  organizationId: z.string().ulid(),
  identifier: z.string().nullish(),
  type: SafetyReportTypeEnum.or(z.string()),
  status: SafetyReportStatusEnum,
  details: z.string(),
  factors: SafetyReportFactorTypeEnum.array(),
  entities: SafetyReportEntitySchema.array(),

  stageOfFlight: FlightStageEnum.nullish(),
  flightLegId: z.string().ulid().nullish(),
  aircraftId: z.string().ulid().nullish(),
  airport: z.string().array().nullish(),
  dateOfIncidence: z.coerce.date().nullish(),

  riskAssessmentSeverity: z.number().min(1).max(5).nullish(),
  riskAssessmentLikelihood: z.number().min(1).max(5).nullish(),
  riskAssessmentDescription: z.string().nullish(),

  createdBy: PartialUserSchema.nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  resolvedAt: z.coerce.date().nullish(),
  resolvedBy: PartialUserSchema.nullish(),
  resolution: z.string().nullish(),
})

export const SafetyReportSearchDocumentSchema = z.object({
  id: z.string().ulid(),
  organizationId: z.string().ulid(),
  organizationName: z.string(),
  identifier: z.string().nullish(),
  type: SafetyReportTypeEnum.or(z.string()),
  status: SafetyReportStatusEnum,
  details: z.string(),
  factors: SafetyReportFactorTypeEnum.array(),

  stageOfFlight: FlightStageEnum.nullish(),
  aircraftTailNumber: z.string().nullish(),
  airport: z.string().array().nullish(),

  riskAssessmentSeverity: z.number().nullish(),
  riskAssessmentSeverityLabel: z.string().nullish(),
  riskAssessmentLikelihood: z.number().nullish(),
  riskAssessmentLikelihoodLabel: z.string().nullish(),
  riskAssessmentDescription: z.string().nullish(),

  createdBy: PartialUserSchema.nullish(),
  createdAt: z.number(),

  accessible_to_user: z.string().nullish(),
  accessible_to_organization: z.string().nullish(),
  globally_accessible: z.boolean(),
})

export const SafetyReportInputSchema = z.object({
  type: SafetyReportTypeEnum,
  details: SafetyReportSchema.shape.details.min(20),
  organizationId: SafetyReportSchema.shape.organizationId,

  stageOfFlight: SafetyReportSchema.shape.stageOfFlight,
  flightLegId: SafetyReportSchema.shape.flightLegId,
  aircraftId: SafetyReportSchema.shape.aircraftId,
  airport: SafetyReportSchema.shape.airport,
  dateOfIncidence: SafetyReportSchema.shape.dateOfIncidence,

  isAnonymous: z.boolean(),
})

export const SafetyReportEventTypeEnum = z.enum([
  "created",
  "risk_assessment_updated",
  "update_by_user",
  "note",
  "processing_finished",
  "status_updated",
  "linked_to_safety_control",
  "unlinked_from_safety_control",
])
export const SafetyReportEventSchema = z.object({
  id: z.string().ulid(),
  safetyReportId: z.string().ulid(),

  userId: z.string().ulid().nullish(),
  userDescription: z.string().nullish(),

  type: SafetyReportEventTypeEnum,
  details: z.object({}).passthrough(),

  createdAt: z.coerce.date(),
})

export const SafetyReportCreatedEventDetailsSchema = z.object({})

export const SafetyReportUserRiskAssessmentUpdateEventDetailsSchema = z.object({})

export const SafetyReportUpdateByUserEventDetailsSchema = z.object({
  fieldsChanged: z.string().array(),
})

export const SafetyReportNoteDetailsSchema = z.object({
  content: z.string(),
})

export const SafetyReportProcessingFinishedDetailsSchema = z.object({})

export const SafetyReportStatusUpdatedDetailsSchema = z.object({
  old: SafetyReportStatusEnum,
  new: SafetyReportStatusEnum,
})

export const SafetyReportLinkedSafetyControlDetailsSchema = z.object({
  id: z.string().ulid(),
})
export const SafetyReportUnlinkedSafetyControlDetailsSchema = z.object({
  id: z.string().ulid(),
})

export type SafetyReportInput = z.infer<typeof SafetyReportInputSchema>
export type SafetyReportStatus = z.infer<typeof SafetyReportStatusEnum>
export type SafetyReportFactorType = z.infer<typeof SafetyReportFactorTypeEnum>
export type SafetyReportEntityType = z.infer<typeof SafetyReportEntityTypeEnum>
export type SafetyReportEntity = z.infer<typeof SafetyReportEntitySchema>
export type SafetyReport = z.infer<typeof SafetyReportSchema>

export const SafetyReportFilterSchema = z.object({
  status: BaseComparatorOperationSchema(SafetyReportStatusEnum).optional(),
  date: BaseComparatorOperationSchema(z.coerce.date()).optional(),
})

export const SafetyReportSortSchema = z.object({
  date: SortEnumSchema.optional(),
})

export type SafetyReportType = z.infer<typeof SafetyReportTypeEnum>
export type SafetyReportFilter = z.infer<typeof SafetyReportFilterSchema>
export type SafetyReportSort = z.infer<typeof SafetyReportSortSchema>
export type FlightStage = z.infer<typeof FlightStageEnum>
export type SafetyReportEvent = z.infer<typeof SafetyReportEventSchema>
export type SafetyReportEventType = z.infer<typeof SafetyReportEventTypeEnum>

export type SafetyReportSearchDocument = z.infer<typeof SafetyReportSearchDocumentSchema>
