import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  List,
  MantineSize,
  Paper,
  Stack,
  Text,
  Textarea,
  UnstyledButton,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useSafetyData } from "@soar/frontend/contexts"
import { AirsigmetTriggerList, AllTriggersList, NotamTriggerList, PirepTriggerList, StructuredTriggerList } from "@soar/shared/threats"
import {
  AirSigmet,
  DataWithIntersections,
  NewNotam,
  Pirep,
  SafetyAssessmentGroup,
  SafetyAssessmentMitigation,
  SafetyAssessmentRecordStatusEnum,
  SafetyAssessmentThreat,
} from "@soar/shared/types"
import { AssessmentThreatColors, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { IconCheck, IconCircleCheck } from "@tabler/icons-react"
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { match } from "ts-pattern"
import { z } from "zod"
import { MaterialSymbol } from "../icons"
import { ModalOrDrawer } from "../modalOrDrawer"
import { StatusDisplay } from "../statusDisplay"
import { AirsigmetDisplay } from "../weather/AirsigmetList"
import { FocusedNotamHeader, NewNotamDisplay } from "../weather/NotamList"
import { PirepDisplay } from "./PirepDisplay"
import { useMitigations } from "./mitigationsAtom"
import { BaseThreatHeader, ThreatHeader } from "./threatHeader"

const useAssessmentThreatStyles = createStyles((theme) => ({
  mitigatedBorder: {
    borderColor: theme.colors["brandBlue"][6],
  },
  withBorder: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "transparent",
  },

  inputFocused: {
    borderColor: theme.colors["brandBlue"][6],
  },
  textAreaWrapper: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.colors.gray[3],
    borderRadius: theme.radius.sm,
  },
}))

const StructuredTriggerSchema = z.object({
  structured: z.object({}),
})

export function AssessmentThreatDisplay({
  threat,
  isMitigated,
  color = "#333",
  mitigation,
  smallSize = false,
}: {
  threat: SafetyAssessmentThreat
  isMitigated: boolean
  mitigation?: SafetyAssessmentMitigation
  color?: string
  smallSize?: boolean
}) {
  const theme = useMantineTheme()
  const severityLevel = getAssessmentThreatScoreLevel(threat.severity)
  const iconSize = smallSize ? 10 : 16
  const [triggerDisplayOpened, triggerDisplayHandlers] = useDisclosure(false)
  const hasStructuredTriggers = useMemo(() => {
    return StructuredTriggerSchema.safeParse(threat.triggers).success
  }, [threat])

  const threatLabel = `${threat.label} +${threat.severity}`
  return (
    <Box>
      <Flex align="flex-start" justify="space-between">
        <BaseThreatHeader
          label={threatLabel}
          icon={
            <Flex align="center" justify="center" w="100%" h="100%">
              <IconCheck height={iconSize} color="white" />
            </Flex>
          }
          order={6}
          color={isMitigated ? theme.colors["brandBlue"][6] : color}
          variant={isMitigated ? "pill" : "outline"}
          fz={"13px"}
          iconSize={smallSize ? 20 : undefined}
        />
        {hasStructuredTriggers && (
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              triggerDisplayHandlers.open()
            }}
            sx={(theme) => ({
              borderRadius: "100%",
              padding: "3px",
              border: "1px solid #000", //${theme.colors.gray[3]}`,
              width: "32px",
              height: "32px",
            })}
            color="dark.9"
          >
            <MaterialSymbol name="manage_search" fz="20px" stroke={700} fill mt={-3} />
          </ActionIcon>
        )}
      </Flex>
      <List listStyleType="none" fz={smallSize ? "sm" : undefined}>
        {threat.reasons.map((reason, index) => (
          <List.Item key={index}>{reason}</List.Item>
        ))}
      </List>
      {mitigation?.reason != null && (
        <Box mt={5} fz={smallSize ? "xs" : "sm"}>
          <Text fw={600} c="gray.8" mb={4}>
            {" "}
            Mitigation:{" "}
          </Text>
          <Text
            pl="xs"
            fs="italic"
            c="dimmed"
            sx={(theme) => ({
              borderLeft: `1px solid ${theme.colors.gray[5]}`,
            })}
          >
            {mitigation.reason}
          </Text>
        </Box>
      )}
      <UnstyledButton
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <ModalOrDrawer
          opened={triggerDisplayOpened}
          onClose={triggerDisplayHandlers.close}
          title=""
          modalProps={{
            size: "xl",
          }}
        >
          <Box pb="xl">
            <Text fz="2rem" fw={700}>
              {threatLabel}
            </Text>
            <Divider mt="sm" mb="xl" />
            <StructuredTriggerListDisplay triggers={threat.triggers} />
          </Box>
        </ModalOrDrawer>
      </UnstyledButton>
    </Box>
  )
}

function parseTriggers(triggers: StructuredTriggerList<AllTriggersList>) {
  if (triggers.structured.type === "multi") {
    const mergedResult = triggers.structured.data.reduce(
      (memo, trigger) => {
        const parseResult = parseBaseTriggers({ structured: trigger })
        return {
          notams: memo.notams.concat(parseResult.notams),
          pireps: memo.pireps.concat(parseResult.pireps),
          airsigmets: memo.airsigmets.concat(parseResult.airsigmets),
        }
      },
      { notams: [], pireps: [], airsigmets: [] } as ReturnType<typeof parseBaseTriggers>,
    )

    return mergedResult
  } else {
    return parseBaseTriggers(triggers)
  }
}

function parseBaseTriggers(triggers: StructuredTriggerList<AllTriggersList>) {
  let notams: NewNotam[] = []
  let pireps: DataWithIntersections<Pirep>[] = []
  let airsigmets: DataWithIntersections<AirSigmet>[] = []

  if (triggers.structured.type === "notam") {
    notams = triggers.structured.data
  }
  if (triggers.structured.type === "pirep") {
    pireps = triggers.structured.data
  }
  if (triggers.structured.type === "airsigmet") {
    airsigmets = triggers.structured.data
  }

  return {
    notams,
    pireps,
    airsigmets,
  }
}

export function StructuredTriggerListDisplay({
  triggers,
}: {
  triggers: StructuredTriggerList<AllTriggersList>
}) {
  const triggerData = useMemo(() => {
    return parseTriggers(triggers)
  }, [triggers])

  const { getPirep, getNotam, getAirsigmet } = useSafetyData()

  return (
    <Stack spacing="xl">
      {triggerData.notams.map((notam) => {
        const notamFromCache = getNotam(`${notam.id}`)
        return (
          <Box key={notam.id}>
            <Text mb={5} fz="lg" fw={700}>
              NOTAM
            </Text>
            <NewNotamDisplay
              notam={notamFromCache?.data ?? notam}
              showExtended
              routeIntersection={notamFromCache?.routeIntersection}
              timeIntersection={notamFromCache?.timeIntersection}
            />
          </Box>
        )
      })}
      {triggerData.pireps.map((pirepWithIntersections) => (
        <Box key={pirepWithIntersections.data.pirepId}>
          <Text mb={5} fz="lg" fw={700}>
            PIREP
          </Text>
          <PirepDisplay pirep={pirepWithIntersections.data} />
        </Box>
      ))}
      {triggerData.airsigmets.map((airsigmetWithIntersections) => (
        <Box key={airsigmetWithIntersections.data.airSigmetId}>
          <Text mb={5} fz="lg" fw={700}>
            {airsigmetWithIntersections.data.airSigmetType}
          </Text>
          <AirsigmetDisplay airsigmet={airsigmetWithIntersections} />
        </Box>
      ))}
    </Stack>
  )
}

export function AssessmentThreatButton({
  threat,
  color,
}: {
  threat: SafetyAssessmentThreat
  color: string | undefined
}) {
  const { mitigations, addMitigation, removeMitigation, briefStatus } = useMitigations()
  const { classes, cx } = useAssessmentThreatStyles()
  const [reason, setReason] = useState("")
  const [inputFocused, setInputFocused] = useState(false)
  const canSubmitReason = useMemo(() => {
    return reason.length > 5
  }, [reason])

  const { mitigation, isMitigated } = useMemo(() => {
    const mitigation = mitigations.find((mitigation) => mitigation.threatId === threat.id)
    const isMitigated = mitigation != null
    return {
      mitigation,
      isMitigated,
    }
  }, [mitigations, threat])

  useEffect(() => {
    if (mitigation?.reason != null) {
      setReason(mitigation.reason)
    }
  }, [mitigation])

  const [reasonCollapseState, reasonCollapseHandlers] = useDisclosure(false)

  const onClick = useCallback(() => {
    const severityLevel = getAssessmentThreatScoreLevel(threat.severity)
    const needsReason = severityLevel === "red" || severityLevel === "orange"

    if (needsReason) {
      reasonCollapseHandlers.open()
    } else if (isMitigated) {
      removeMitigation(threat.id)
    } else {
      addMitigation(threat.id, threat.identifier)
    }
  }, [threat, isMitigated, reason, setReason])

  const onSubmitClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      removeMitigation(threat.id)
      addMitigation(threat.id, threat.identifier, reason)
      reasonCollapseHandlers.close()
    },
    [reason, threat, reasonCollapseHandlers],
  )

  const inputRef = useRef<HTMLTextAreaElement>(null)

  return (
    <UnstyledButton key={threat.id} onClick={onClick} disabled={briefStatus?.status === SafetyAssessmentRecordStatusEnum.enum.finished}>
      <Paper mb="sm" px="sm" py="md" radius="md" shadow="sm" className={cx(classes.withBorder, { [classes.mitigatedBorder]: isMitigated })}>
        <AssessmentThreatDisplay threat={threat} isMitigated={isMitigated} color={color} />

        <Collapse
          in={reasonCollapseState}
          onTransitionEnd={() => {
            if (reasonCollapseState) {
              inputRef.current?.focus()
            }
          }}
        >
          <Box className={cx(classes.textAreaWrapper, { [classes.inputFocused]: inputFocused })} mt="sm" pb={5} px={5}>
            <Textarea
              autoFocus
              ref={inputRef}
              autosize
              onChange={(e) => setReason(e.target.value)}
              onFocus={() => {
                setInputFocused(true)
              }}
              onBlur={() => {
                setInputFocused(false)
              }}
              value={reason}
              placeholder="Add how you’ll mitigate this hazard"
              styles={{
                input: {
                  border: 0,
                },
              }}
            />
            <Flex justify="flex-end">
              <ActionIcon variant="filled" radius="xl" color="brandBlue" onClick={onSubmitClick} disabled={!canSubmitReason}>
                <IconCheck height={16} width={16} />
              </ActionIcon>
            </Flex>
          </Box>
        </Collapse>
        {!reasonCollapseState && mitigation?.reason != null && mitigation?.reason?.length > 0 && (
          <Box mt="sm" fz="xs">
            <Text fw={600}>Mitigation: </Text>
            <Text c="dimmed">{mitigation.reason}</Text>
          </Box>
        )}
      </Paper>
    </UnstyledButton>
  )
}

export function AssessmentThreatList({
  assessmentThreats,
}: {
  assessmentThreats?: SafetyAssessmentGroup
}) {
  const { mitigations, addMitigation, removeMitigation, briefStatus } = useMitigations()
  const { classes, cx } = useAssessmentThreatStyles()
  const safetyAssessmentLevel = assessmentThreats != null ? getAssessmentThreatScoreLevel(assessmentThreats?.score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined
  const sortedThreats = useMemo(() => {
    return [...(assessmentThreats?.threats ?? [])].sort((a, b) => {
      return b.severity - a.severity
    })
  }, [assessmentThreats])

  if (assessmentThreats?.threats.length === 0) {
    return (
      <Box bg="#F2F7FF" py="xl">
        <StatusDisplay
          label="No hazards to mitigate"
          color="brandBlue.7"
          icon={IconCircleCheck}
          iconProps={{ size: 128 }}
          labelProps={{
            color: "black",
            fw: 600,
          }}
        />
      </Box>
    )
  }
  return (
    <>
      <Stack spacing={8} maw="100%" px="sm" py="lg">
        {sortedThreats.map((threat, index) => (
          <AssessmentThreatButton threat={threat} color={safetyAssessmentColor} />
        ))}
      </Stack>
    </>
  )
}

export function AssessmentThreatDisplayList({
  assessmentThreats,
  mitigations = [],
}: {
  assessmentThreats?: SafetyAssessmentGroup
  mitigations?: SafetyAssessmentMitigation[]
}) {
  const { classes, cx } = useAssessmentThreatStyles()
  const safetyAssessmentLevel = assessmentThreats != null ? getAssessmentThreatScoreLevel(assessmentThreats?.score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined

  if (assessmentThreats?.threats.length === 0) {
    return (
      <Box py="sm">
        <Text c="dimmed">No risks detected</Text>
      </Box>
    )
  }
  return (
    <>
      <Stack spacing="sm" maw="100%" py="sm">
        {assessmentThreats?.threats.map((threat, index) => {
          const mitigation = mitigations.find((mitigation) => mitigation.identifier === threat.identifier)
          return (
            <AssessmentThreatDisplay threat={threat} color={safetyAssessmentColor} mitigation={mitigation} isMitigated={false} smallSize />
          )
        })}
      </Stack>
    </>
  )
}
