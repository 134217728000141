import { SafetyControlSearchDocument, SafetyControlStatus, SafetyControlStatusEnum } from "@soar/shared/types"
import { getTypesenseCollectionName, getTypesenseSearchClient } from "./typesenseClient"

export const SAFETY_CONTROL_IDENTIFIER_PREFIX = "RC"

type SafetyControlStatusDetails = {
  label: string
  color: string
}

export const SafetyControlStatusConfig: Record<SafetyControlStatus, SafetyControlStatusDetails> = {
  [SafetyControlStatusEnum.enum.drafted]: {
    label: "Drafted",
    color: "dark.8",
  },
  [SafetyControlStatusEnum.enum.under_review]: {
    label: "Under review",
    color: "yellow.8",
  },
  [SafetyControlStatusEnum.enum.operational]: {
    label: "Operational",
    color: "green.8",
  },
  [SafetyControlStatusEnum.enum.retired]: {
    label: "Retired",
    color: "brandBlue.8",
  },
}

type TypesenseSearchOptions = {
  filterBy?: string
}

export async function getSafetyControlsResultsTypesense(query: string, apiKey: string, options: TypesenseSearchOptions = {}) {
  const client = getTypesenseSearchClient(apiKey)
  const results = await client.collections(getTypesenseCollectionName("safety_controls")).documents().search(
    {
      q: query,
      query_by: "identifier, title, objective, implementationPlan, factors, status",
      filter_by: options.filterBy,
      per_page: 15,
    },
    {},
  )

  const parsedResults = (results.hits ?? []).map((hit) => {
    return hit.document as SafetyControlSearchDocument
  })
  // console.log("parsedResults: ", parsedResults)
  return { results: parsedResults }
}
