import { Box } from "@mantine/core"
import { RiskAssessmentBaseLevel } from "@soar/shared/types"
import { RiskAssessmentLevelConfig, calculateRiskAssessmentLevel } from "@soar/shared/utils"
import { P, match } from "ts-pattern"

export function RiskAssessmentLevelCircle({
  severity,
  likelihood,
  size,
}: {
  severity?: RiskAssessmentBaseLevel | number | null
  likelihood?: RiskAssessmentBaseLevel | number | null
  size?: number
}) {
  return match({ severity: severity, likelihood: likelihood })
    .with({ severity: P.nonNullable, likelihood: P.nonNullable }, ({ severity, likelihood }) => {
      const riskLevel = calculateRiskAssessmentLevel(likelihood as RiskAssessmentBaseLevel, severity as RiskAssessmentBaseLevel)
      const color = RiskAssessmentLevelConfig[riskLevel].color
      return (
        <Box
          style={{
            width: `${size ?? 25}px`,
            height: `${size ?? 25}px`,
            backgroundColor: color,
            borderRadius: "100%",
          }}
        />
      )
    })
    .otherwise(() => {
      return <></>
    })
}
