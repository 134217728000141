import {
  Box,
  Button,
  Divider,
  Flex,
  Paper,
  Progress,
  RingProgress,
  Skeleton,
  Text,
  Title,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { AssessmentThreatLevelsEnum, ImSafeEnum, SafetyAssessment, SafetyBriefMode } from "@soar/shared/types"
import {
  AssessmentThreatColors,
  AssessmentThreatLabels,
  DefaultAssessmentThreatCutoffs,
  formatDate,
  getAssessmentThreatScoreLevel,
} from "@soar/shared/utils"
import { IconCheck, IconMap } from "@tabler/icons-react"
import { useMemo } from "react"
import { useMitigations } from "./mitigationsAtom"
import { SafetyThermometer } from "./safetyThermometer"

const useStyles = createStyles((theme) => ({
  mapButton: {
    [theme.fn.smallerThan("sm")]: {
      borderRadius: theme.radius.xl,
    },
  },
}))

function MapButton({
  isSmallSize,
  onClick,
}: {
  onClick: () => void
  isSmallSize: boolean
}) {
  const { classes } = useStyles()

  return (
    <Button
      variant={isSmallSize ? "filled" : "subtle"}
      h={{ base: "60%", sm: "100%" }}
      className={classes.mapButton}
      onClick={onClick}
      py={{ base: "4px", sm: "0" }}
      color="brandBlue.6"
    >
      <Flex direction={{ base: "row", sm: "column" }} align="center" justify="center">
        <svg viewBox="0 -960 960 960" width="28">
          <title>Map Icon</title>
          <use href="/map_FILL1_wght400_GRAD0_opsz24.svg#main" fill="currentColor" stroke="currentColor" width="24" height="24" />
        </svg>

        <Text fz="sm" ml={{ base: 8, sm: 0 }}>
          View map
        </Text>
      </Flex>
    </Button>
  )
}

/*
export function ProgressIndicator({
  isSmallSize,
}: {
  isSmallSize: boolean
}) {
  const { mitigations, valueMitigated, numThreats, isFinishedMitigating, briefStatus, onSave } = useMitigations()
  const theme = useMantineTheme()
  if (briefStatus?.status === "finished" && briefStatus.finishedAt != null) {
    return (
      <Flex ta="center" c="dimmed" fz="sm" direction="column">
        <RingProgress
          sections={[{ value: valueMitigated, color: "teal" }]}
          size={isSmallSize ? 70 : 80}
          label={
            <Flex justify="center" align="center">
              <IconCheck color={theme.colors.teal[7]} strokeWidth={5} />
            </Flex>
          }
        />
        <Text ta="center" fw={700} fz="sm">
          {mitigations.length} / {numThreats}
        </Text>
      </Flex>
    )
  }
  if (isFinishedMitigating) {
    return (
      <Button radius="xl" color="teal.9" onClick={onSave}>
        Submit Assessment
      </Button>
    )
  }

  return (
    <Flex align="center" direction={{ base: "row", sm: "column" }}>
      <RingProgress sections={[{ value: valueMitigated, color: "teal" }]} size={isSmallSize ? 70 : 80} label={undefined} />
      <Text ta="center" fw={700} fz="sm">
        {mitigations.length} / {numThreats}
      </Text>
    </Flex>
  )
}
*/

export function SummaryCard({
  numThreats,
  onMapClick = () => {},
  loading = false,
  safetyBriefMode,
  safetyAssessment,
}: {
  numThreats: number
  loading?: boolean
  onMapClick?: () => void
  safetyBriefMode: SafetyBriefMode
  safetyAssessment?: SafetyAssessment
}) {
  const theme = useMantineTheme()
  const isSmallSize = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { numThreats: numSafetyThreats, isFinishedMitigating } = useMitigations()
  const numThreatsToDisplay = useMemo(() => {
    if (safetyBriefMode === "data") {
      return numThreats
    }
    if (safetyAssessment == null) {
      return -1
    }

    return numSafetyThreats
  }, [numThreats, numSafetyThreats, safetyAssessment, safetyBriefMode])

  const { totalScore, scoreColor, level, scoreLabel } = useMemo(() => {
    if (safetyAssessment == null) {
      return { totalScore: 0, level: AssessmentThreatLevelsEnum.enum.clear }
    }
    const totalScore =
      safetyAssessment.preflight.score +
      safetyAssessment.departure.score +
      safetyAssessment.enroute.score +
      safetyAssessment.destination.score
    const level = getAssessmentThreatScoreLevel(totalScore, DefaultAssessmentThreatCutoffs.overall)
    const scoreColor = AssessmentThreatColors[level]
    const scoreLabel = AssessmentThreatLabels[level]

    return {
      totalScore,
      scoreColor,
      scoreLabel,
      level,
    }
  }, [safetyAssessment])

  return (
    <Paper radius="lg" py="sm" px="lg" shadow="lg">
      <Flex justify="space-between" align="stretch" direction={{ base: "column", sm: "row" }}>
        <Box style={{ flexGrow: 1 }}>
          <Title order={4} style={{ fontSize: "1.3rem" }}>
            Summary
          </Title>
          {loading || (numThreatsToDisplay ?? -1) < 0 ? (
            <Skeleton w="85%" h={8} display="inline-block" />
          ) : safetyBriefMode === "data" ? (
            <Text>We've identified {numThreatsToDisplay} threats to assess before departure.</Text>
          ) : (
            <Text>
              Your total risk score falls into the
              <Text fw={700} span c={scoreColor}>
                {" "}
                {scoreLabel}{" "}
              </Text>
              range.
            </Text>
          )}
        </Box>
        <Divider orientation="horizontal" display={{ base: "block", sm: "none" }} my={{ base: "md", sm: "sm" }} />
        <Flex align="center" justify={{ base: "center", md: "flex-start" }}>
          <Divider orientation="vertical" display={{ base: "none", sm: "block" }} pr={{ base: 0, sm: "lg" }} />
          {safetyBriefMode === "data" ? (
            <>
              <MapButton isSmallSize={isSmallSize} onClick={onMapClick} />
            </>
          ) : (
            <SafetyThermometer cutoffs={DefaultAssessmentThreatCutoffs.overall} maxValue={100} value={totalScore} size={100} thickness={35}>
              <Flex align="flex-end" mt={-25} style={{ verticalAlign: "sub" }}>
                <Text span fz="24px" lh={1} fw={700} c={scoreColor}>
                  {totalScore}
                </Text>
                <Text span fz="10px" lh={1} pb={2}>
                  / 100
                </Text>
              </Flex>
            </SafetyThermometer>
          )}
        </Flex>
      </Flex>
    </Paper>
  )
}
