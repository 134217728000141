import { ActionIcon, Box, Button, ButtonProps, Checkbox, Chip, Flex, Select, SimpleGrid, Stack, Text, Textarea, Title } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import {
  BUTTON_GRADIENT_COLORS,
  DepartureDestinationDisplay,
  FlightLegResultCard,
  FlightLegSelect,
  ModalOrDrawer,
  useCustomChipOutlinedStyles,
  useCustomChipStyles,
} from "@soar/frontend/components"
import { organizationsForUserAtom, useAircraft } from "@soar/frontend/contexts"
import { trpc } from "@soar/frontend/trpc-client"
import {
  Aircraft,
  FlightStage,
  FlightStageEnum,
  Organization,
  SafetyReport,
  SafetyReportInput,
  SafetyReportInputSchema,
  SafetyReportSchema,
  SafetyReportType,
  SafetyReportTypeEnum,
  SchedulingFlightLegSearchDocument,
} from "@soar/shared/types"
import { FlightStageConfig, SafetyReportTypeConfig } from "@soar/shared/utils"
import { useAtomValue } from "jotai"
import { ReactNode, useEffect, useState } from "react"
import { AirportInput } from "../airports/airportInput"

export function SafetyReportModal({
  opened,
  onClose = () => {},
  onSuccess = () => {},
}: {
  opened: boolean
  onClose?: () => void
  onSuccess?: (post: SafetyReport) => void
}) {
  const { mutateAsync, isLoading } = trpc.safetyReport.createSafetyReport.useMutation()
  const organizations = useAtomValue(organizationsForUserAtom)
  const { aircraftOptions } = useAircraft()

  const handleSubmit = async (safetyReport: SafetyReportInput) => {
    const safetyReportResponse = await mutateAsync({
      ...safetyReport,
    })
    const safetyReportParsed = SafetyReportSchema.parse(safetyReportResponse)
    onClose()
    showNotification({
      color: "green",
      message: "Safety report has been submitted!",
    })
    onSuccess(safetyReportParsed)
  }

  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Submit safety report</Title>}
      modalProps={{
        size: "lg",
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
      drawerProps={{
        closeOnClickOutside: false,
        closeOnEscape: false,
      }}
    >
      <Box>
        <SafetyReportForm
          organizations={organizations ?? []}
          onSubmit={handleSubmit}
          aircraftOptions={aircraftOptions}
          isSubmitting={isLoading}
        />
      </Box>
    </ModalOrDrawer>
  )
}

const stageOfFlightOptions = Object.keys(FlightStageConfig).map((key) => {
  const config = FlightStageConfig[key]
  return {
    label: config.label,
    value: key,
  }
})

function getRelevantStageOfFlightAirports(flightStage: FlightStage | null | undefined, type: SafetyReportType) {
  if (type === "ground") {
    return {
      departure: true,
      destination: false,
    }
  }
  if (flightStage == null) {
    return {
      departure: true,
      destination: false,
    }
  }
  return FlightStageConfig[flightStage].appliesTo
}

const OptionalText = (
  <Text span fs="italic" fz="xs" c="dimmed">
    optional
  </Text>
)

function SafetyReportForm({
  onSubmit = () => {},
  organizations,
  aircraftOptions,
  isSubmitting,
}: {
  isSubmitting?: boolean
  onSubmit?: (safetyReport: SafetyReportInput) => void
  organizations: Organization[]
  aircraftOptions: Array<{ label: string; value: string; tailNumber: string; organizationId: string | undefined | null }>
}) {
  const form = useForm<SafetyReportInput>({
    initialValues: {
      type: SafetyReportTypeEnum.enum.flight,
      details: "",
      organizationId: "",
      isAnonymous: false,
    },
    validate: zodResolver(SafetyReportInputSchema),
  })
  const [flightLeg, setFlightLeg] = useState<SchedulingFlightLegSearchDocument>()
  const [departureAirport, setDepartureAirport] = useState("")
  const [destinationAirport, setDestinationAirport] = useState("")
  const { cx, classes } = useCustomChipOutlinedStyles({ borderStyle: "solid" })

  useEffect(() => {
    if (organizations != null) {
      form.setValues({
        organizationId: organizations[0].id,
      })
    }
  }, [organizations])

  const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight, form.values.type)

  useEffect(() => {
    const airports: string[] = []
    const stageOfFlightAirportRelevance = getRelevantStageOfFlightAirports(form.values.stageOfFlight, form.values.type)

    if (stageOfFlightAirportRelevance.departure && departureAirport.length > 0) {
      airports.push(departureAirport)
    }
    if (stageOfFlightAirportRelevance.destination && destinationAirport.length > 0) {
      airports.push(destinationAirport)
    }
    form.setFieldValue("airport", airports)
  }, [departureAirport, destinationAirport, form.values.stageOfFlight])

  const onFlightLegSelect = (flightLeg: SchedulingFlightLegSearchDocument) => {
    form.setFieldValue("organizationId", flightLeg.organizationId ?? "")
    form.setFieldValue("flightLegId", flightLeg.id)
    form.setFieldValue("dateOfIncidence", new Date(flightLeg.departAt))

    setDepartureAirport(flightLeg.departureAirport)
    setDestinationAirport(flightLeg.destinationAirport)

    const matchedAircraft = aircraftOptions.find(
      (option) => option.tailNumber === flightLeg.aircraftTailNumber && option.organizationId === flightLeg.organizationId,
    )
    form.setFieldValue("aircraftId", matchedAircraft?.value)
    setFlightLeg(flightLeg)
  }
  const onRemoveFlightLegClick = () => {
    form.setFieldValue("organizationId", "")
    form.setFieldValue("flightId", undefined)
    form.setFieldValue("dateOfIncidence", undefined)
    form.setFieldValue("aircraftId", undefined)
    setFlightLeg(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (values) => {
        onSubmit(values)
      })}
    >
      <Stack>
        <Chip.Group {...form.getInputProps("type")}>
          <SimpleGrid cols={2} style={{ order: 1 }}>
            <Chip value={SafetyReportTypeEnum.enum.flight} classNames={classes}>
              {SafetyReportTypeConfig[SafetyReportTypeEnum.enum.flight].label}
            </Chip>
            <Chip value={SafetyReportTypeEnum.enum.ground} classNames={classes}>
              {SafetyReportTypeConfig[SafetyReportTypeEnum.enum.ground].label}
            </Chip>
          </SimpleGrid>
        </Chip.Group>
        <Stack
          style={{
            order: form.values.type === "ground" ? 15 : 2,
          }}
        >
          {flightLeg == null ? (
            <FlightLegSelect
              onSelect={onFlightLegSelect}
              textInputProps={{
                label: <Text>Flight leg {OptionalText}</Text>,
                placeholder: "Search for the flight",
              }}
            />
          ) : (
            <Box>
              <Text fz="sm" fw={500}>
                Flight leg {OptionalText}
              </Text>
              <Flex justify="space-between" align="flex-start">
                <DepartureDestinationDisplay from={flightLeg.departureAirport} to={flightLeg.destinationAirport} size="xs" />

                <Button variant="subtle" onClick={onRemoveFlightLegClick} px={0} mt={-10}>
                  Change flight
                </Button>
              </Flex>
            </Box>
          )}
          <Select
            disabled={form.values.type === "ground"}
            display={form.values.type === "ground" ? "none" : "block"}
            data={stageOfFlightOptions}
            label={<Text>Stage of flight {OptionalText}</Text>}
            {...form.getInputProps("stageOfFlight")}
            placeholder="Select when the issue occurred (e.g., Takeoff)"
          />
        </Stack>

        <DatePickerInput
          label="Date"
          {...form.getInputProps("dateOfIncidence")}
          // @ts-ignore
          placeholder="Select the observation date"
          style={{ order: 3 }}
          valueFormat="MMM D, YYYY"
          popoverProps={{
            middlewares: {
              flip: false,
              shift: false,
              inline: false,
            },
          }}
          styles={(theme) => ({
            day: {
              "&[data-weekend]": {
                color: theme.colors.brandBlue[5],
                "&[data-selected]": {
                  color: "#FFF",
                },
              },
            },
          })}
        />
        {stageOfFlightAirportRelevance.departure && (
          <Box style={{ order: 4 }}>
            <Text fz="sm" fw={500}>
              {form.values.type === "flight" && form.values.stageOfFlight === "cruise" ? "Departure location" : "Location"}
            </Text>
            <AirportInput
              onSelect={(airport) => {
                setDepartureAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              placeholder="Search for the airport"
              value={departureAirport}
            />
          </Box>
        )}
        {stageOfFlightAirportRelevance.destination && (
          <Box style={{ order: 5 }}>
            <Text fz="sm" fw={500}>
              {form.values.type === "flight" && form.values.stageOfFlight === "cruise" ? "Arrival location" : "Location"}
            </Text>
            <AirportInput
              placeholder="Search for the airport"
              onSelect={(airport) => {
                setDestinationAirport(airport?.icao_id ?? airport?.faa_airport_id ?? "")
              }}
              value={destinationAirport}
            />
          </Box>
        )}

        <Select
          data={aircraftOptions}
          label={<Text>Equipment {OptionalText}</Text>}
          {...form.getInputProps("aircraftId")}
          style={{ order: 6 }}
          placeholder="Select the aircraft"
        />

        <Textarea
          label="Description"
          {...form.getInputProps("details")}
          autosize
          minRows={5}
          style={{ order: 20 }}
          placeholder="Describe what happened..."
        />
        <Select
          label="Organization"
          data={organizations?.map((org) => ({ value: org.id, label: org.name })) ?? []}
          {...form.getInputProps("organizationId")}
          style={{ order: 30 }}
        />

        <Checkbox label="Anonymous?" {...form.getInputProps("isAnonymous")} style={{ order: 40 }} />

        <Button
          variant="gradient"
          gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }}
          type="submit"
          style={{ order: 50 }}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  )
}
