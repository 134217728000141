import { Box, Chip, SimpleGrid } from "@mantine/core"
import { DashboardTimespan, FlightLegStatus, FlightLegStatusEnum } from "@soar/shared/types"
import { useCustomChipOutlinedStyles } from "../customChip"
import { TimespanChipGroup } from "./TimespanChipGroup"

export function FlightLegControls({
  timespan,
  filter,
  onChange = () => {},
}: {
  timespan?: DashboardTimespan
  filter: FlightLegStatus[]
  onChange?: (timespan: DashboardTimespan | undefined, filter: FlightLegStatus[]) => void
}) {
  const { classes: customChipClasses } = useCustomChipOutlinedStyles({})

  const onFilterChange = (filter: FlightLegStatus[]) => {
    onChange(timespan, filter)
  }

  const onTimespanChange = (timespan: DashboardTimespan) => {
    onChange(timespan, filter)
  }

  return (
    <Box pt="sm" pb={{ base: "sm", md: 0 }}>
      <SimpleGrid
        cols={5}
        mb="md"
        breakpoints={[
          { maxWidth: "xl", cols: 5, spacing: "lg" },
          { maxWidth: "xs", cols: 5, spacing: 5 },
        ]}
      >
        <Chip
          value="all"
          classNames={customChipClasses}
          size="lg"
          checked={filter.length === 0}
          onChange={() => {
            onFilterChange([])
          }}
        >
          All
        </Chip>
        <Chip.Group
          multiple
          onChange={(values) => {
            if (values != null) {
              onFilterChange(values as FlightLegStatus[])
            }
          }}
          value={filter}
        >
          <Chip classNames={customChipClasses} size="lg" value={FlightLegStatusEnum.enum.created}>
            Not started
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={FlightLegStatusEnum.enum.awaiting_approval}>
            In review
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={FlightLegStatusEnum.enum.approved}>
            Released
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={FlightLegStatusEnum.enum.on_hold}>
            On hold
          </Chip>
        </Chip.Group>
      </SimpleGrid>
      <Box fz="sm">
        <TimespanChipGroup value={timespan} onChange={onTimespanChange} />
      </Box>
    </Box>
  )
}
