import { Badge, Flex, MantineSize, Text } from "@mantine/core"
import { RiskAssessmentBaseLevel } from "@soar/shared/types"
import {
  RiskAssessmentLevelConfig,
  RiskAssessmentLikelihoodConfig,
  RiskAssessmentSeverityConfig,
  calculateRiskAssessmentLevel,
} from "@soar/shared/utils"
import { useMemo } from "react"

export function RiskAssessmentBadge({
  likelihood,
  severity,
  size,
}: {
  likelihood: RiskAssessmentBaseLevel
  severity: RiskAssessmentBaseLevel
  size?: MantineSize
}) {
  const { level, color } = useMemo(() => {
    const level = calculateRiskAssessmentLevel(likelihood, severity)
    const color = RiskAssessmentLevelConfig[level].color

    return {
      level,
      color,
    }
  }, [likelihood, severity])
  return (
    <Badge
      style={{
        backgroundColor: color,
        color: "#FFF",
      }}
      size={size}
    >
      <Flex>
        <Text>Likelihood: {RiskAssessmentLikelihoodConfig[likelihood].label}</Text>
        <Text>;&nbsp;</Text>
        <Text>Severity: {RiskAssessmentSeverityConfig[severity].label}</Text>
      </Flex>
    </Badge>
  )
}
