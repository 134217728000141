import { ZodSchema, z } from "zod"
import { SafetyControlStatusEnum } from "./Status"

export const SafetyControlEventTypeEnum = z.enum([
  "created",
  "update_by_user",
  "note",
  "status_updated",
  "relation_added",
  "relation_removed",
])

export const SafetyControlEventSchema = z.object({
  id: z.string().ulid(),
  safetyControlId: z.string().ulid(),

  userId: z.string().ulid().nullish(),
  userDescription: z.string().nullish(),

  type: SafetyControlEventTypeEnum,
  details: z.object({}).passthrough(),

  createdAt: z.coerce.date(),
})
export type SafetyControlEventType = z.infer<typeof SafetyControlEventTypeEnum>
export type SafetyControlEvent = z.infer<typeof SafetyControlEventSchema>

export const SafetyControlCreatedEventDetailsSchema = z.object({})

export const SafetyControlUpdateByUserEventDetailsSchema = z.object({
  fieldsChanged: z.string().array(),
})

export const SafetyControlNoteEventDetailsSchema = z.object({
  content: z.string(),
})

export const SafetyControlStatusUpdatedEventDetailsSchema = z.object({
  old: SafetyControlStatusEnum,
  new: SafetyControlStatusEnum,
})

export const SafetyControlRelationAddedEventDetailsSchema = z.object({
  type: z.string(),
  id: z.string(),
  description: z.string(),
})

export const SafetyControlRelationRemovedEventDetailsSchema = z.object({
  type: z.string(),
  id: z.string(),
  description: z.string(),
})

export const SafetyControlEventTypeSchemaConfig = {
  [SafetyControlEventTypeEnum.enum.created]: SafetyControlCreatedEventDetailsSchema,
  [SafetyControlEventTypeEnum.enum.update_by_user]: SafetyControlUpdateByUserEventDetailsSchema,
  [SafetyControlEventTypeEnum.enum.note]: SafetyControlNoteEventDetailsSchema,
  [SafetyControlEventTypeEnum.enum.status_updated]: SafetyControlStatusUpdatedEventDetailsSchema,
  [SafetyControlEventTypeEnum.enum.relation_added]: SafetyControlRelationAddedEventDetailsSchema,
  [SafetyControlEventTypeEnum.enum.relation_removed]: SafetyControlRelationRemovedEventDetailsSchema,
} as const
