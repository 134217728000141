import {
  FlightStage,
  RiskAssessmentBaseLevel,
  RiskAssessmentCombinedLevelEnum,
  SafetyReportFactorType,
  SafetyReportSearchDocument,
  SafetyReportStatus,
  SafetyReportType,
  SafetyReportTypeEnum,
} from "@soar/shared/types"
import { match } from "ts-pattern"
import { getTypesenseCollectionName, getTypesenseSearchClient } from "./typesenseClient"

export const SAFETY_REPORT_IDENTIFIER_PREFIX = "SR"

export type SafetyReportFactorConfigDetails = {
  label: string
  categories: SafetyReportType[]
}

export const SafetyReportFactorConfig: Record<SafetyReportFactorType, SafetyReportFactorConfigDetails> = {
  weather_condition: {
    label: "Weather condition",
    categories: [],
  },
  pilot_error: {
    label: "Pilot error",
    categories: [],
  },
  mechanical_failure: {
    label: "Mechanical failure",
    categories: [],
  },
  atc_issue: {
    label: "ATC issue",
    categories: [],
  },
  fuel_management: {
    label: "Fuel management",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  runway_incursion: {
    label: "Runway incursion",
    categories: [],
  },
  bird_strike: {
    label: "Bird strike",
    categories: [],
  },
  procedure_deviation: {
    label: "Procedure deviation",
    categories: [],
  },
  emergency_readiness: {
    label: "Emergency readiness",
    categories: [],
  },
  crew_fatigue: {
    label: "Crew fatigue",
    categories: [],
  },
  training_gap: {
    label: "Training gap",
    categories: [],
  },
  communication_failure: {
    label: "Communication failure",
    categories: [],
  },
  security_threat: {
    label: "Security threat",
    categories: [],
  },
  cargo_handling: {
    label: "Cargo handling",
    categories: [],
  },
  navigation_error: {
    label: "Navigation error",
    categories: [],
  },
  ground_incident: {
    label: "Ground incident",
    categories: [],
  },
  regulatory_compliance: {
    label: "Regulatory compliance",
    categories: [],
  },
  passenger_safety: {
    label: "Passenger safety",
    categories: [],
  },
  medical_emergency: {
    label: "Medical emergency",
    categories: [],
  },
  maintenance_issue: {
    label: "Maintenance issue",
    categories: [],
  },

  wildlife: {
    label: "Wildlife",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  weather: {
    label: "Weather",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  turbulence: {
    label: "Turbulence",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  system_malfunction: {
    label: "System malfunction",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  flight_planning: {
    label: "Flight planning",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  congestion: {
    label: "Congestion",
    categories: [SafetyReportTypeEnum.enum.flight, SafetyReportTypeEnum.enum.ground],
  },
  human_error: {
    label: "Human error",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  fatigue: {
    label: "Fatigue",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  navigation: {
    label: "Navigation",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  maintenance: {
    label: "Maintenance",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  foreign_object_debris: {
    label: "Foreign object debris (FOD)",
    categories: [SafetyReportTypeEnum.enum.flight, SafetyReportTypeEnum.enum.ground],
  },
  weight_and_balance: {
    label: "Weight and balance",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  charts_and_data: {
    label: "Charts and data",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  communication: {
    label: "Communication",
    categories: [SafetyReportTypeEnum.enum.flight, SafetyReportTypeEnum.enum.ground],
  },
  situational_awareness: {
    label: "Situational awareness",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  uncontrolled_airspace: {
    label: "Uncontrolled airspace",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  drones: {
    label: "Drones",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  distractions: {
    label: "Distractions",
    categories: [SafetyReportTypeEnum.enum.flight],
  },
  visibility: {
    label: "Visibility",
    categories: [SafetyReportTypeEnum.enum.flight],
  },

  hazmat: {
    label: "Hazmat",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  fueling: {
    label: "Fueling",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  signage: {
    label: "Signage",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  ground_training: {
    label: "Ground training",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  lighting: {
    label: "Lighting",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  equipment_malfunction: {
    label: "Equipment malfunction",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  slippery_surfaces: {
    label: "Slippery surfaces",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  obstructions: {
    label: "Obstructions",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  procedures: {
    label: "Procedures",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  deicing: {
    label: "Deicing",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  towing: {
    label: "Towing",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  ladders_and_stands: {
    label: "Ladders and stands",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  exits_and_access: {
    label: "Exits and access",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  unauthorized_personnel: {
    label: "Unauthorized personnel",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  surface_damage: {
    label: "Surface damage",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  chocks_and_brakes: {
    label: "Chocks and brakes",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
  loading: {
    label: "Loading",
    categories: [SafetyReportTypeEnum.enum.ground],
  },
}

export type SafetyReportStatusConfigDetails = {
  label: string
  colorType: "mantine" | "custom"
  color: string
}

export const SafetyReportStatusConfig: Record<SafetyReportStatus, SafetyReportStatusConfigDetails> = {
  created: {
    label: "Logged",
    colorType: "mantine",
    color: "dark.8",
  },
  underReview: {
    label: "Under Review",
    colorType: "mantine",
    color: "yellow.8",
  },
  inProgress: {
    label: "Action in progress",
    colorType: "mantine",
    color: "yellow.8",
  },
  resolved: {
    label: "Closed",
    colorType: "mantine",
    color: "brandBlue.8",
  },
}

export const SafetyReportTypeConfig: Record<
  SafetyReportType,
  {
    label: string
  }
> = {
  [SafetyReportTypeEnum.enum.flight]: {
    label: "Flight",
  },
  [SafetyReportTypeEnum.enum.ground]: {
    label: "Ground",
  },
}

type FlightStageConfigDetails = {
  label: string
  appliesTo: {
    departure: boolean
    destination: boolean
  }
}

export const FlightStageConfig: Record<FlightStage, FlightStageConfigDetails> = {
  pre_taxi: {
    label: "Pre-taxi",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  taxi_out: {
    label: "Taxi out",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  takeoff: {
    label: "Takeoff",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  climb: {
    label: "Climb",
    appliesTo: {
      departure: true,
      destination: false,
    },
  },
  cruise: {
    label: "Cruise",
    appliesTo: {
      departure: true,
      destination: true,
    },
  },
  descent: {
    label: "Descent",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  approach: {
    label: "Approach",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  landing: {
    label: "Landing",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  taxi_in: {
    label: "Taxi out",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
  shutdown: {
    label: "Shutdown",
    appliesTo: {
      departure: false,
      destination: true,
    },
  },
}

export const RiskAssessmentLikelihoodConfig: Record<
  RiskAssessmentBaseLevel,
  {
    label: string
  }
> = {
  1: {
    label: "Extremely Improbable",
  },
  2: {
    label: "Improbable",
  },
  3: {
    label: "Remote",
  },
  4: {
    label: "Occasional",
  },
  5: {
    label: "Frequent",
  },
}

export const RiskAssessmentSeverityConfig: Record<
  RiskAssessmentBaseLevel,
  {
    label: string
  }
> = {
  1: {
    label: "Negligible",
  },
  2: {
    label: "Minor",
  },
  3: {
    label: "Major",
  },
  4: {
    label: "Hazardous",
  },
  5: {
    label: "Catastrophic",
  },
}

export function calculateRiskAssessmentLevel(likelihood: RiskAssessmentBaseLevel, severity: RiskAssessmentBaseLevel) {
  return match({ likelihood, severity })
    .with(
      { likelihood: 5, severity: 5 },
      { likelihood: 5, severity: 4 },
      { likelihood: 5, severity: 3 },
      { likelihood: 4, severity: 5 },
      { likelihood: 4, severity: 4 },
      { likelihood: 3, severity: 5 },
      () => {
        return RiskAssessmentCombinedLevelEnum.enum.high
      },
    )
    .with(
      { likelihood: 5, severity: 2 },
      { likelihood: 5, severity: 1 },
      { likelihood: 4, severity: 3 },
      { likelihood: 4, severity: 2 },
      { likelihood: 4, severity: 1 },
      { likelihood: 3, severity: 4 },
      { likelihood: 3, severity: 3 },
      { likelihood: 3, severity: 2 },
      { likelihood: 2, severity: 5 },
      { likelihood: 2, severity: 4 },
      { likelihood: 2, severity: 3 },
      () => {
        return RiskAssessmentCombinedLevelEnum.enum.medium
      },
    )
    .otherwise(() => RiskAssessmentCombinedLevelEnum.enum.low)
}

export const RiskAssessmentLevelConfig = {
  low: {
    color: "#37B24D",
  },
  medium: {
    color: "#F88113",
  },
  high: {
    color: "#F95349",
  },
}

type TypesenseSearchOptions = {
  filterBy?: string
  per_page?: number
}

export async function getSafetyReportsResultsTypesense(query: string, apiKey: string, options: TypesenseSearchOptions = {}) {
  const client = getTypesenseSearchClient(apiKey)
  const results = await client
    .collections(getTypesenseCollectionName("safety_reports"))
    .documents()
    .search(
      {
        q: query,
        query_by:
          "details, factors, identifier, type, status, airport, stageOfFlight, aircraftTailNumber, riskAssessmentSeverityLabel, riskAssessmentLikelihoodLabel, createdBy.firstName, createdBy.lastName, riskAssessmentDescription",
        filter_by: options.filterBy,
        per_page: options.per_page ?? 15,
      },
      {},
    )

  const parsedResults = (results.hits ?? []).map((hit) => {
    return hit.document as SafetyReportSearchDocument
  })
  // console.log("parsedResults: ", parsedResults)
  return { results: parsedResults }
}
