import { z } from "zod"
import { SafetyReportFactorTypeEnum } from "../SafetyReport"
import { BaseComparatorOperationSchema } from "../utility"
import { SafetyControlStatusEnum } from "./Status"

export const SafetyControlSchema = z.object({
  id: z.string().ulid(),
  organizationId: z.string().ulid(),
  identifier: z.string(),
  status: SafetyControlStatusEnum,
  factors: SafetyReportFactorTypeEnum.array(),

  title: z.string().min(3),
  objective: z.string().min(10),
  implementationPlan: z.string().nullish(),

  createdByUserId: z.string().ulid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
})

export const SafetyControlInputSchema = SafetyControlSchema.pick({
  organizationId: true,
  factors: true,
  title: true,
  objective: true,
  implementationPlan: true,
})

export const SafetyControlSearchSchema = z.object({
  id: z.string().ulid(),
  organizationId: z.string().ulid(),
  identifier: z.string(),
  status: SafetyControlStatusEnum,
  factors: SafetyReportFactorTypeEnum.array(),

  title: z.string(),
  objective: z.string(),
  implementationPlan: z.string(),

  createdByUserId: z.string().ulid(),
  createdAt: z.number().int(),
  updatedAt: z.number().int(),

  accessible_to_user: z.string().nullish(),
  accessible_to_organization: z.string().nullish(),
  globally_accessible: z.boolean(),
})

export const SafetyControlReferenceSchema = z.object({
  id: z.string().ulid(),
  safetyControlId: z.string().ulid(),
  safetyReportId: z.string().ulid(),
  createdByUserId: z.string().ulid(),
  createdAt: z.coerce.date(),
})

export const SafetyControlFilterSchema = z.object({
  status: BaseComparatorOperationSchema(SafetyControlStatusEnum).optional(),
  date: BaseComparatorOperationSchema(z.coerce.date()).optional(),
})

export type SafetyControl = z.infer<typeof SafetyControlSchema>
export type SafetyControlInput = z.infer<typeof SafetyControlInputSchema>
export type SafetyControlFilter = z.infer<typeof SafetyControlFilterSchema>
export type SafetyControlSearchDocument = z.infer<typeof SafetyControlSearchSchema>
export type SafetyControlReference = z.infer<typeof SafetyControlReferenceSchema>
