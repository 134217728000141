import { KeyboardEvent, useCallback, useEffect, useState } from "react"

export const useKeyboardSelection = ({
  itemCount,
  onSelect,
  onClose,
}: {
  itemCount: number
  onSelect: (selectedIndex: number) => void
  onClose: () => void
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  useEffect(() => {
    setSelectedIndex(undefined)
  }, [itemCount])

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault()

        if (itemCount > 0 && selectedIndex != null && selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1)
        }
        // isColumn ? handlePrevious() : handleNext();
        break
      }

      case "ArrowDown": {
        e.preventDefault()
        if (itemCount > 0) {
          setSelectedIndex((selectedIndex ?? -1) + 1)
        }
        // isColumn ? handleNext() : handlePrevious();
        break
      }

      case "Enter": {
        e.preventDefault()
        if (selectedIndex != null) {
          onSelect(selectedIndex)
        }
        break
      }

      case "Escape": {
        e.preventDefault()
        onClose()
        break
      }
    }
  }

  return {
    selectedIndex,
    onKeyDown,
  }
}
