import { createStyles } from "@mantine/core"

export const useCustomChipStyles = createStyles((theme) => ({
  checkIcon: {
    display: "none",
  },
  iconWrapper: {
    display: "none",
  },
  label: {
    backgroundColor: "transparent",
    fontSize: theme.fontSizes.xs,
    paddingLeft: "15px",
    paddingRight: "15px",
    ["&[data-checked]"]: {
      paddingLeft: "15px",
      paddingRight: "15px",
      [theme.fn.smallerThan("xs")]: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    [theme.fn.smallerThan("xs")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
}))

type CustomChipOutlineConfig = {
  borderStyle?: string
  filled?: boolean
}

export const useCustomChipOutlinedStyles = createStyles((theme, config?: CustomChipOutlineConfig) => ({
  checkIcon: {
    display: "none",
  },
  iconWrapper: {
    display: "none",
  },
  root: {
    width: "100%",
  },
  label: {
    backgroundColor: "transparent",
    borderStyle: config?.borderStyle ?? "dashed",
    borderWidth: "1px",
    padding: "26px 0",
    width: "100%",
    height: "1em",
    lineHeight: "1.5em",
    textAlign: "center",
    justifyContent: "center",
    wordWrap: "break-word",
    whiteSpace: "normal",
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.md,
    ["&[data-checked]"]: {
      paddingLeft: "0",
      paddingRight: "0",
      color: config?.filled ? "#FFF" : "inherit",
      backgroundColor: config?.filled ? theme.colors["brandBlue"][6] : "inherit",
      ["&:hover"]: {
        backgroundColor: config?.filled ? theme.colors["brandBlue"][6] : "transparent",
      },
    },
    ["&:hover"]: {
      backgroundColor: "transparent",
      // backdropFilter: "brightness(97%)",
    },
    [theme.fn.smallerThan("md")]: {
      fontSize: theme.fontSizes.sm,
    },
    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.xs,
    },
  },
}))
