import { MantineTheme, ModalProps, createStyles } from "@mantine/core"

export const usePickerStyles = createStyles((theme) => ({
  itemWrapper: {
    borderRadius: "5px",
    padding: "6px 6px",
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWidth: 500,
    "&:hover, &.selected": {
      backgroundColor: theme.colors["brandBlue"][1],
    },
  },
  textInput: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    fontSize: "1.1em",
    "&:focus": {
      borderColor: "transparent",
    },
  },
}))

export const PickerModalProps: Partial<ModalProps> = {
  size: "lg",
  centered: true,
  closeOnEscape: true,
  closeOnClickOutside: true,
  withCloseButton: false,
  overlayProps: {
    opacity: 0.2,
    blur: 7,
  },
  shadow: "xl",
  styles: (theme: MantineTheme) => ({
    header: {
      display: "none",
    },
    content: {
      backgroundColor: theme.white,
      overflowY: "hidden !important" as "hidden",
    },
    body: {
      padding: "0.25em",
    },
    inner: {},
  }),
}
