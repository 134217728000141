import { MantineStyleSystemProps, createStyles } from "@mantine/core"

export const useInfoPageStyles = createStyles((theme) => ({
  headingBorder: {
    borderBottom: `thin solid ${theme.colors.gray[3]}`,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
  },
}))

export const infoPageSectionProps: MantineStyleSystemProps = {
  pb: "2xl",
  mb: "xl",
}
