import { Box, Chip, SimpleGrid } from "@mantine/core"
import { DashboardTimespan, SafetyReportStatus, SafetyReportStatusEnum } from "@soar/shared/types"
import { SafetyReportStatusConfig } from "@soar/shared/utils"
import { useCustomChipOutlinedStyles } from "../customChip"
import { TimespanChipGroup } from "./TimespanChipGroup"

export function SafetyReportControls({
  timespan,
  filter,
  onChange,
}: {
  timespan?: DashboardTimespan
  filter: SafetyReportStatus[]
  onChange: (timespan: DashboardTimespan | undefined, filter: SafetyReportStatus[]) => void
}) {
  const { classes: customChipClasses } = useCustomChipOutlinedStyles({})

  const onFilterChange = (filter: SafetyReportStatus[]) => {
    onChange(timespan, filter)
  }

  const onTimespanChange = (timespan: DashboardTimespan) => {
    onChange(timespan, filter)
  }

  return (
    <Box pt="sm" pb={{ base: "sm", md: 0 }}>
      <SimpleGrid
        cols={5}
        mb="md"
        breakpoints={[
          { maxWidth: "xl", cols: 5, spacing: "lg" },
          { maxWidth: "xs", cols: 5, spacing: 5 },
        ]}
      >
        <Chip
          value="all"
          classNames={customChipClasses}
          size="lg"
          checked={filter.length === 0}
          onChange={() => {
            onFilterChange([])
          }}
        >
          All
        </Chip>
        <Chip.Group
          multiple
          onChange={(values) => {
            if (values != null) {
              onFilterChange(values as SafetyReportStatus[])
            }
          }}
          value={filter}
        >
          <Chip classNames={customChipClasses} size="lg" value={SafetyReportStatusEnum.enum.created}>
            {SafetyReportStatusConfig[SafetyReportStatusEnum.enum.created].label}
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={SafetyReportStatusEnum.enum.underReview}>
            {SafetyReportStatusConfig[SafetyReportStatusEnum.enum.underReview].label}
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={SafetyReportStatusEnum.enum.inProgress}>
            {SafetyReportStatusConfig[SafetyReportStatusEnum.enum.inProgress].label}
          </Chip>
          <Chip classNames={customChipClasses} size="lg" value={SafetyReportStatusEnum.enum.resolved}>
            {SafetyReportStatusConfig[SafetyReportStatusEnum.enum.resolved].label}
          </Chip>
        </Chip.Group>
      </SimpleGrid>
      <Box fz="sm">
        <TimespanChipGroup value={timespan} onChange={onTimespanChange} />
      </Box>
    </Box>
  )
}
