import { ActionIcon, Box, Flex, Textarea, TextareaProps, createStyles } from "@mantine/core"
import { IconCheck, IconX } from "@tabler/icons-react"
import { MouseEventHandler, useRef, useState } from "react"

const useCustomBorderTextAreaStyles = createStyles((theme) => ({
  inputFocused: {
    borderColor: theme.colors["brandBlue"][6],
  },
  textAreaWrapper: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.colors.gray[3],
    borderRadius: theme.radius.sm,
  },
}))

export function CustomBorderTextArea({
  textAreaProps,
  onSubmitClick = () => {},
  onCancel,
  isSaveButtonHidden = false,
  isSaveButtonDisabled = false,
  clearOnSubmit = false,
}: {
  textAreaProps?: TextareaProps
  onSubmitClick?: (value: string) => void
  onCancel?: () => void
  isSaveButtonDisabled?: boolean
  isSaveButtonHidden?: boolean
  clearOnSubmit?: boolean
}) {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [inputFocused, setInputFocused] = useState(false)
  const { cx, classes } = useCustomBorderTextAreaStyles()

  const handleSubmitClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    const value = inputRef.current?.value ?? ""
    inputRef.current?.blur()
    setInputFocused(false)
    onSubmitClick(value)
    if (clearOnSubmit && inputRef.current != null) {
      inputRef.current.value = ""
    }
  }

  return (
    <Box
      className={cx(classes.textAreaWrapper, { [classes.inputFocused]: inputFocused })}
      mt="sm"
      pb={5}
      px={5}
      onClick={() => {
        setInputFocused(true)
        inputRef.current?.focus()
      }}
    >
      <Textarea
        ref={inputRef}
        autosize
        onFocus={(e) => {
          setInputFocused(true)
          if (textAreaProps?.onFocus != null) {
            textAreaProps.onFocus(e)
          }
        }}
        onBlur={(e) => {
          setInputFocused(false)
          if (textAreaProps?.onBlur != null) {
            textAreaProps.onBlur(e)
          }
        }}
        styles={{
          root: {
            backgroundColor: "transparent",
          },
          input: {
            border: 0,
            backgroundColor: "transparent",
          },
        }}
        {...textAreaProps}
      />
      <Flex justify="flex-end" gap="md">
        {onCancel != null && (
          <ActionIcon variant="filled" radius="xl" color="gray" onClick={onCancel}>
            <IconX height={16} width={16} />
          </ActionIcon>
        )}
        {!isSaveButtonHidden && (
          <ActionIcon variant="filled" radius="xl" color="brandBlue" onClick={handleSubmitClick} disabled={isSaveButtonDisabled}>
            <IconCheck height={16} width={16} />
          </ActionIcon>
        )}
      </Flex>
    </Box>
  )
}
