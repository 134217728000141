import { z } from "zod"

export const RiskAssessmentBaseLevel = z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)])
export const RiskAssessmentCombinedLevelEnum = z.enum(["low", "medium", "high"])

export type RiskAssessmentInput = {
  likelihood: RiskAssessmentBaseLevel | undefined
  severity: RiskAssessmentBaseLevel | undefined
  description: string | undefined
}

export const RiskAssessmentSchema = z.object({
  likelihood: RiskAssessmentBaseLevel,
  severity: RiskAssessmentBaseLevel,
  description: z.string(),
})

export type RiskAssessment = z.infer<typeof RiskAssessmentSchema>
export type RiskAssessmentBaseLevel = z.infer<typeof RiskAssessmentBaseLevel>
export type RiskAssessmentCombinedLevel = z.infer<typeof RiskAssessmentCombinedLevelEnum>
