import {
  ActionIcon,
  Affix,
  Box,
  Button,
  Card,
  CloseButton,
  Divider,
  Flex,
  Group,
  Indicator,
  Paper,
  Stack,
  Switch,
  Tabs,
  Text,
  Title,
  createStyles,
} from "@mantine/core"
import { computeWeatherBasedOnTime, formatDate, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import "./weatherBriefing.scss"

import {
  AdditionalWeatherDataPackage,
  AirSigmet,
  Aircraft,
  AirportResult,
  DataWithIntersections,
  FlightMetrics,
  IEnrouteRisk,
  LoadingStatus,
  MetarResponse,
  NewNotam,
  Notam,
  PirateWeather,
  Pirep,
  Risk,
  RiskPayload,
  RouteSegment,
  SafetyAssessment,
  SafetyAssessmentThreat,
  SafetyBriefMode,
  ScoreAdjustment,
  ScoreAdjustmentSchema,
  StageOfFlight,
  TafResponse,
} from "@soar/shared/types"

import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure, useMergedRef } from "@mantine/hooks"
import { IconX, IconZoomIn } from "@tabler/icons-react"
import { ReactNode, Ref, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MaterialSymbol } from "../icons"
import { SidewayAirplaneIcon } from "../icons/sidewayAirplaneIcon"
import { ModalOrDrawer } from "../modalOrDrawer"
import { AirportDetailSafetyView, AirportOverviewSafetyCard, useMitigations } from "../safety"
import { ScoreAdjustmentView } from "../safety/ScoreAdjustmentView"
import { DepartureDestinationDisplay } from "../safety/departureDestinationDisplay"
import { SafetyBriefModeToggle } from "../safety/detailCardCommon"
import { EnrouteDetailSafetyView } from "../safety/enrouteDetailSafetyView"
import { EnrouteOverviewSafetyCard } from "../safety/enrouteOverviewSafetyCard"
import { SafetyFloatingButton } from "../safety/floatingButton"
import { PreflightDetailSafetyCard } from "../safety/preflightDetailSafetyCard"
import { PreflightOverviewSafetyCard } from "../safety/preflightOverviewSafetyCard"
import { SummaryCard } from "../safety/summaryCard"
import { TimezoneContext } from "../safety/timezoneContext"
import { SegmentedControl } from "../segmentedControl"
import { BUTTON_GRADIENT_COLORS } from "../tokens"
import { ResultMap } from "./ResultMap"

dayjs.extend(utc)
dayjs.extend(timezone)

const useWeatherBriefingStyles = createStyles((theme) => ({
  hideOverflow: {
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  positionRelative: {
    position: "relative",
  },
  noPadding: {
    padding: 0,
  },
  topPadding: {
    paddingTop: "20px",
  },
}))

function isNotamActive(notam: Notam, date: Date) {
  const hasEndDate = notam.effectiveEnd != null
  const hasStartDate = notam.effectiveStart != null

  if (!hasEndDate && hasStartDate && date > notam.effectiveStart!) {
    return true
  }

  if (!(hasEndDate || hasStartDate)) {
    return true
  }

  if (hasEndDate && hasStartDate && date > notam.effectiveStart! && date < notam.effectiveEnd!) {
    return true
  }

  return false
}

const SECONDS_PER_MINUTE = 60 as const
const MINUTES_PER_HOUR = 60 as const
const SIGMET_TIME_BUFFER = 4 * MINUTES_PER_HOUR * SECONDS_PER_MINUTE

//<Flex fz="sm" fw={700} c="gray.6" align="center" gap="xs" py="5px">

function MitigationsNeededIndicator({
  briefMode,
  threats,
  children,
}: {
  briefMode: SafetyBriefMode
  threats: SafetyAssessmentThreat[]
  children: ReactNode
}) {
  const { mitigations } = useMitigations()
  const isMitigated = useMemo(() => {
    let isMitigated = true
    for (const threat of threats) {
      const mitigation = mitigations.find((mitigation) => mitigation.identifier === threat.identifier && mitigation.threatId === threat.id)
      if (mitigation == null) {
        isMitigated = false
        break
      }
    }
    return isMitigated
  }, [mitigations, threats])

  return (
    <Indicator disabled={isMitigated || briefMode === "data"}>
      <Box pr={isMitigated ? 7 : 7}>{children}</Box>
    </Indicator>
  )
}

export const WeatherBriefing = forwardRef(
  (
    {
      shown = false,
      tafs,
      metars,
      departureWeather,
      destinationWeather,
      destinationNotams,
      departureNotams,
      enrouteNotams,
      tafsStatus,
      metarsStatus,
      departureNotamsStatus,
      destinationNotamsStatus,
      enrouteNotamsStatus,
      departureWeatherStatus,
      destinationWeatherStatus,
      departure,
      destination,
      aircraft,
      date,
      airsigmets,
      airsigmetsStatus,
      risks,
      risksStatus,
      newDepartureNotams,
      newDestinationNotams,
      newEnrouteNotams,
      newDepartureNotamsStatus,
      newDestinationNotamsStatus,
      newEnrouteNotamsStatus,
      departureNotamsRisks,
      destinationNotamsRisks,
      enrouteRisks,
      flightMetrics,
      additionalWeatherData,
      pireps,
      safetyAssessment,
      exactRoute,
    }: {
      shown?: boolean
      tafs?: Record<string, TafResponse>
      metars?: Record<string, MetarResponse>
      departureWeather?: PirateWeather
      destinationWeather?: PirateWeather
      aircraft?: Aircraft
      departure?: AirportResult
      destination?: AirportResult
      destinationNotams?: Notam[]
      departureNotams?: Notam[]
      enrouteNotams?: Notam[]
      newDepartureNotams?: DataWithIntersections<NewNotam>[]
      newDestinationNotams?: DataWithIntersections<NewNotam>[]
      newEnrouteNotams?: DataWithIntersections<NewNotam>[]
      airsigmets?: DataWithIntersections<AirSigmet>[]
      risks?: RiskPayload
      date?: Date
      departureNotamsRisks?: Risk[]
      destinationNotamsRisks?: Risk[]
      tafsStatus: LoadingStatus
      metarsStatus: LoadingStatus
      departureWeatherStatus: LoadingStatus
      destinationWeatherStatus: LoadingStatus
      departureNotamsStatus: LoadingStatus
      destinationNotamsStatus: LoadingStatus
      enrouteNotamsStatus: LoadingStatus
      airsigmetsStatus: LoadingStatus
      risksStatus: LoadingStatus
      newDepartureNotamsStatus: LoadingStatus
      newDestinationNotamsStatus: LoadingStatus
      newEnrouteNotamsStatus: LoadingStatus
      enrouteRisks?: IEnrouteRisk
      flightMetrics?: FlightMetrics
      additionalWeatherData?: AdditionalWeatherDataPackage
      pireps?: DataWithIntersections<Pirep>[]
      safetyAssessment?: SafetyAssessment
      exactRoute?: RouteSegment[]
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { classes, cx } = useWeatherBriefingStyles()
    const [rawMode, setRawMode] = useState<boolean>(false)
    const [briefMode, setBriefMode] = useState<SafetyBriefMode>("assessment")
    const [resultMapOpened, resultMapHandlers] = useDisclosure(false)
    const departureCode = departure?.icao_id ?? departure?.faa_airport_id
    const destinationCode = destination?.icao_id ?? destination?.faa_airport_id

    const departureMetar = metars?.[departureCode ?? ""]
    const destinationMetar = metars?.[destinationCode ?? ""]

    const departureTaf = tafs?.["departureTaf"]
    const destinationTaf = tafs?.["destinationTaf"]

    const eteHours = flightMetrics != null ? Math.floor(flightMetrics?.ete) : undefined
    const eteMinutes = flightMetrics != null ? Math.floor(60 * (flightMetrics.ete - eteHours!)) : undefined
    const flightTimeString = flightMetrics != null ? `${eteHours}h${eteMinutes}m` : undefined

    const departureRisks = useMemo(() => {
      if (departureNotamsRisks == null || risks == null) {
        return undefined
      }

      return departureNotamsRisks.concat(risks.departure).sort((a, b) => b.severity - a.severity)
    }, [departureNotamsRisks, risks])

    const destinationRisks = useMemo(() => {
      if (destinationNotamsRisks == null || risks == null) {
        return undefined
      }
      return destinationNotamsRisks.concat(risks.destination).sort((a, b) => b.severity - a.severity)
    }, [destinationNotamsRisks, risks])

    const departureWeatherDetails = useMemo(() => {
      if (flightMetrics != null && departureTaf != null) {
        return computeWeatherBasedOnTime(flightMetrics?.departureDate.toDate(), {
          metar: departureMetar,
          taf: departureTaf,
          weather: departureWeather,
        })
      }
      return null
    }, [flightMetrics, departureMetar, departureTaf, departureWeather])

    const destinationWeatherDetails = useMemo(() => {
      if (flightMetrics != null && destinationTaf != null) {
        return computeWeatherBasedOnTime(flightMetrics.eta.toDate(), {
          metar: destinationMetar,
          taf: destinationTaf,
          weather: destinationWeather,
        })
      }
      return null
    }, [flightMetrics, destinationMetar, destinationTaf, destinationWeather])

    const isEnrouteLoading = airsigmetsStatus !== "success" || newEnrouteNotamsStatus !== "success"
    const isDepartureLoading = [tafsStatus, metarsStatus, departureWeatherStatus, newDepartureNotamsStatus].every(
      (status) => status !== "success",
    )
    const isDestinationLoading = [tafsStatus, metarsStatus, destinationWeatherStatus, newDestinationNotamsStatus].every(
      (status) => status !== "success",
    )

    const [detailModalOpened, detailModalHandlers] = useDisclosure(false)
    const [detailModalView, setDetailModalView] = useState<StageOfFlight | "preflight">("departure")
    const onExpandClick = useCallback(
      (stageOfFlight: StageOfFlight | "preflight") => {
        setDetailModalView(stageOfFlight)
        detailModalHandlers.open()
      },
      [setDetailModalView, detailModalHandlers],
    )

    const onRawModeChange = (checked: boolean) => {
      setRawMode(checked)
    }

    const isLoading = isEnrouteLoading || isDepartureLoading || isDestinationLoading
    const totalRisks = useMemo(() => {
      if (isLoading) {
        return -1
      }
      const departureRisksCount = (departureRisks ?? []).length
      const destinationRisksCount = (destinationRisks ?? []).length

      const enrouteNotamsRisksCount = (enrouteRisks?.notamRisks ?? []).length
      const enrouteAirsigmetRiskCount = (enrouteRisks?.airSigmetRisks ?? []).length
      const enrouteAirspaceRisksCount = (enrouteRisks?.airspace ?? []).flatMap((airspaceWithIntersections) => {
        return airspaceWithIntersections.data.risks
      }).length

      return departureRisksCount + destinationRisksCount + enrouteNotamsRisksCount + enrouteAirsigmetRiskCount + enrouteAirspaceRisksCount
    }, [departureRisks, destinationRisks, departureNotamsRisks, destinationNotamsRisks, enrouteRisks, isLoading])

    const { onSave } = useMitigations()
    const { score, adjustmentNeeded } = useMemo(() => {
      const score =
        safetyAssessment == null
          ? 0
          : safetyAssessment.preflight.score +
            safetyAssessment.departure.score +
            safetyAssessment.enroute.score +
            safetyAssessment.destination.score
      const level = getAssessmentThreatScoreLevel(score)
      const adjustmentNeeded = level === "red"
      return { score, level, adjustmentNeeded }
    }, [safetyAssessment])

    const [scoreAdjustmentModalState, scoreAdjustmentModalHandlers] = useDisclosure(false)

    const onMitigationsSave = useCallback(() => {
      detailModalHandlers.close()
      if (adjustmentNeeded) {
        scoreAdjustmentModalHandlers.open()
      } else {
        onSave()
      }
    }, [onSave, adjustmentNeeded])

    const scoreAdjustmentForm = useForm<ScoreAdjustment>({
      validate: zodResolver(ScoreAdjustmentSchema),
    })
    const blahref = useRef<HTMLDivElement>(null)
    const mergedRef = useMergedRef(ref, blahref)

    return (
      <>
        <TimezoneContext.Provider
          value={{ departureTimeZone: flightMetrics?.departure.timeZoneName, destinationTimeZone: flightMetrics?.destination.timeZoneName }}
        >
          <Box
            ref={mergedRef}
            w="100%"
            h={shown ? undefined : 0}
            className={cx(classes.positionRelative)}
            style={{ visibility: shown ? "visible" : "hidden", overflow: shown ? "initial" : "hidden" }}
          >
            <Stack>
              <DepartureDestinationDisplay from={departureCode ?? ""} to={destinationCode ?? ""} />

              <SummaryCard
                numThreats={totalRisks}
                onMapClick={resultMapHandlers.open}
                loading={isLoading || totalRisks < 0}
                safetyBriefMode={"assessment"}
                safetyAssessment={safetyAssessment}
              />
              <PreflightOverviewSafetyCard
                threats={safetyAssessment?.preflight.threats}
                score={safetyAssessment?.preflight.score}
                onExpandClick={onExpandClick}
              />
              <AirportOverviewSafetyCard
                risks={departureRisks}
                airport={departure}
                stageOfFlight="departure"
                date={flightMetrics?.departureDate.toDate()}
                onExpandClick={onExpandClick}
                flightRules={departureWeatherDetails?.flightRules}
                loading={isDepartureLoading}
                airportFlightMetrics={flightMetrics?.departure}
                safetyBriefMode={"assessment"}
                assessmentThreats={safetyAssessment?.departure}
              />
              <EnrouteOverviewSafetyCard
                risks={enrouteRisks}
                from={departureCode ?? ""}
                to={destinationCode ?? ""}
                flightTime={flightTimeString}
                onExpandClick={onExpandClick}
                loading={isEnrouteLoading}
                safetyBriefMode={"assessment"}
                assessmentThreats={safetyAssessment?.enroute}
              />
              <AirportOverviewSafetyCard
                risks={destinationRisks}
                airport={destination}
                stageOfFlight="destination"
                date={flightMetrics?.eta.toDate()}
                onExpandClick={onExpandClick}
                flightRules={destinationWeatherDetails?.flightRules}
                loading={isDestinationLoading}
                airportFlightMetrics={flightMetrics?.destination}
                safetyBriefMode={"assessment"}
                assessmentThreats={safetyAssessment?.destination}
              />
            </Stack>
          </Box>
          <Flex
            justify="center"
            pos={{ base: "fixed", md: "sticky" }}
            left="50%"
            right="50%"
            bottom={{ base: "80px", sm: "20px" }}
            style={{
              zIndex: 200,
              visibility: shown ? "visible" : "hidden",
              paddingBottom: "calc(calc(env(safe-area-inset-bottom) / 2 ) + 5px)",
              backgroundColor: "transparent",
            }}
          >
            <Button
              size="sm"
              radius="xl"
              leftIcon={<MaterialSymbol name="checklist" fz="xl" />}
              disabled={isLoading}
              variant="gradient"
              gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }}
              sx={(theme) => ({
                boxShadow: "2px 2px 8px rgba(0,0,0, 0.6)",
                "&:disabled": {
                  backgroundColor: theme.colors.gray[5],
                  color: theme.colors.gray[1],
                },
              })}
              onClick={() => {
                if (briefMode === "assessment") {
                  onExpandClick("preflight")
                } else {
                  onExpandClick("departure")
                }
              }}
            >
              Review hazards
            </Button>
          </Flex>

          <ResultMap
            opened={resultMapOpened}
            onClose={resultMapHandlers.close}
            departure={departure}
            destination={destination}
            enrouteNotams={newEnrouteNotams}
            airSigmets={airsigmets}
            rawMode={rawMode}
            pireps={pireps}
            timeZone={departureWeather?.timezone}
            enrouteRisks={enrouteRisks}
            exactRoute={exactRoute}
            departureDetails={{
              time: flightMetrics?.departureDate.toDate(),
              flightRules: departureWeatherDetails?.flightRules,
              flightMetrics: flightMetrics?.departure,
            }}
            destinationDetails={{
              time: flightMetrics?.eta.toDate(),
              flightRules: destinationWeatherDetails?.flightRules,
              flightMetrics: flightMetrics?.destination,
            }}
          >
            <Paper shadow="lg" p="sm" radius="lg">
              <Flex justify="space-between" align="center">
                <Title order={3} fw={700} color="dark">
                  <Flex align="flex-start" justify="space-between" w="100%" gap="sm">
                    <Flex align="flex-start" direction="column">
                      <Text lh={1}>{departureCode}</Text>
                      <Text size="xs" c="dimmed" tt="lowercase">
                        {flightMetrics?.departureDate != null &&
                          formatDate(flightMetrics?.departureDate, { time: true, timeZone: flightMetrics?.departure.timeZoneName })}
                      </Text>
                    </Flex>
                    <Text mt={9} fz="xs">
                      <SidewayAirplaneIcon height="16" />
                    </Text>
                    <Flex align="flex-start" direction="column">
                      <Text lh={1}>{destinationCode}</Text>
                      <Text size="xs" c="dimmed" tt="lowercase">
                        {flightMetrics?.eta != null &&
                          formatDate(flightMetrics?.eta, { time: true, timeZone: flightMetrics?.destination.timeZoneName })}
                      </Text>
                    </Flex>
                  </Flex>
                </Title>

                <Divider orientation="vertical" mx="lg" />

                <Switch
                  labelPosition="left"
                  label="Raw"
                  size="xs"
                  fw={700}
                  checked={rawMode}
                  onChange={(e) => {
                    setRawMode(e.target.checked)
                  }}
                />
              </Flex>
            </Paper>
          </ResultMap>
          <ModalOrDrawer
            id="weather-briefing-modal"
            opened={detailModalOpened}
            onClose={detailModalHandlers.close}
            modalProps={{
              size: "xl",
              withCloseButton: false,
              styles: {
                root: {
                  overflow: "hidden",
                },
                body: {
                  height: "100%",
                },
              },
              classNames: {
                content: classes.hideOverflow,
                body: cx(classes.hideOverflow, classes.positionRelative, classes.noPadding),
              },
            }}
            drawerProps={{
              withCloseButton: false,
              classNames: {
                content: cx(classes.hideOverflow),
                body: cx(classes.hideOverflow, classes.positionRelative, classes.noPadding, classes.topPadding),
              },
            }}
          >
            <SafetyFloatingButton
              onMitigationsSave={onMitigationsSave}
              onMapClick={() => {
                resultMapHandlers.open()
                detailModalHandlers.close()
              }}
              safetyBriefMode={briefMode}
            />
            <Tabs
              value={detailModalView}
              onTabChange={(view) => {
                setDetailModalView(view as StageOfFlight)
              }}
              classNames={{
                root: classes.hideOverflow,
                panel: classes.hideOverflow,
              }}
              pt="sm"
            >
              <Tabs.List position="center">
                {briefMode !== "data" && (
                  <Tabs.Tab value="preflight">
                    <MitigationsNeededIndicator briefMode={briefMode} threats={safetyAssessment?.preflight.threats ?? []}>
                      Preflight
                    </MitigationsNeededIndicator>
                  </Tabs.Tab>
                )}
                <Tabs.Tab value="departure">
                  <MitigationsNeededIndicator briefMode={briefMode} threats={safetyAssessment?.departure.threats ?? []}>
                    Departure
                  </MitigationsNeededIndicator>
                </Tabs.Tab>
                <Tabs.Tab value="enroute">
                  <MitigationsNeededIndicator briefMode={briefMode} threats={safetyAssessment?.enroute.threats ?? []}>
                    En Route
                  </MitigationsNeededIndicator>
                </Tabs.Tab>
                <Tabs.Tab value="destination">
                  <MitigationsNeededIndicator briefMode={briefMode} threats={safetyAssessment?.destination.threats ?? []}>
                    Arrival
                  </MitigationsNeededIndicator>
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="preflight">
                <PreflightDetailSafetyCard threats={safetyAssessment?.preflight} />
              </Tabs.Panel>
              <Tabs.Panel value="departure">
                <AirportDetailSafetyView
                  metar={departureMetar}
                  taf={departureTaf}
                  weatherRisks={risks?.departure ?? []}
                  notamRisks={departureNotamsRisks ?? []}
                  airport={departure}
                  stageOfFlight="departure"
                  date={flightMetrics?.departureDate.toDate()}
                  flightRules={departureWeatherDetails?.flightRules}
                  weatherDetails={departureWeatherDetails!}
                  notams={newDepartureNotams ?? []}
                  rawMode={rawMode}
                  airportFlightMetrics={flightMetrics?.departure}
                  additionalWeatherData={additionalWeatherData?.departure}
                  onRawModeChange={onRawModeChange}
                  safetyBriefMode={briefMode}
                  assessmentThreats={safetyAssessment?.departure}
                  onSafetyBriefModeChange={setBriefMode}
                />
              </Tabs.Panel>
              <Tabs.Panel value="enroute">
                <EnrouteDetailSafetyView
                  from={departureCode ?? ""}
                  to={destinationCode ?? ""}
                  flightTime={flightTimeString}
                  notams={newEnrouteNotams}
                  risks={enrouteRisks}
                  airsigmetsStatus={airsigmetsStatus}
                  airSigmets={airsigmets}
                  rawMode={rawMode}
                  onRawModeChange={onRawModeChange}
                  pireps={pireps}
                  safetyBriefMode={briefMode}
                  assessmentThreats={safetyAssessment?.enroute}
                  onSafetyBriefModeChange={setBriefMode}
                  departureAirport={departure}
                  onMapClick={() => {
                    resultMapHandlers.open()
                    detailModalHandlers.close()
                  }}
                />
              </Tabs.Panel>
              <Tabs.Panel value="destination">
                <AirportDetailSafetyView
                  metar={destinationMetar}
                  taf={destinationTaf}
                  weatherRisks={risks?.destination ?? []}
                  notamRisks={destinationNotamsRisks ?? []}
                  airport={destination}
                  stageOfFlight="destination"
                  date={flightMetrics?.eta.toDate()}
                  flightRules={destinationWeatherDetails?.flightRules}
                  weatherDetails={destinationWeatherDetails!}
                  notams={newDestinationNotams ?? []}
                  rawMode={rawMode}
                  airportFlightMetrics={flightMetrics?.destination}
                  additionalWeatherData={additionalWeatherData?.destination}
                  onRawModeChange={onRawModeChange}
                  safetyBriefMode={briefMode}
                  assessmentThreats={safetyAssessment?.destination}
                  onSafetyBriefModeChange={setBriefMode}
                />
              </Tabs.Panel>
            </Tabs>

            <ActionIcon onClick={detailModalHandlers.close} style={{ position: "absolute", top: "10px", right: "10px" }}>
              <IconX fontWeight={600} strokeWidth={3} color="black" />
            </ActionIcon>
          </ModalOrDrawer>
          <ModalOrDrawer
            modalProps={{
              size: "lg",
            }}
            opened={scoreAdjustmentModalState}
            onClose={scoreAdjustmentModalHandlers.close}
          >
            <ScoreAdjustmentView
              score={score}
              form={scoreAdjustmentForm}
              onSubmit={(values) => {
                scoreAdjustmentModalHandlers.close()
                onSave(values)
              }}
            />
          </ModalOrDrawer>
        </TimezoneContext.Provider>
      </>
    )
  },
)
