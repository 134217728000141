import { Box, Flex, Paper, Stack, Text, UnstyledButton } from "@mantine/core"
import { SafetyControl, SafetyControlSearchDocument } from "@soar/shared/types"
import { SAFETY_CONTROL_IDENTIFIER_PREFIX, formatDate } from "@soar/shared/utils"
import dayjs from "dayjs"
import { Ref, forwardRef } from "react"
import { SafetyControlStatusDisplay } from "./StatusDisplay"

function SafetyControlResultCardBase({
  safetyControl,
}: {
  safetyControl: SafetyControl | SafetyControlSearchDocument
}) {
  return (
    <UnstyledButton w="100%">
      <Paper py="sm" px="lg" radius={0}>
        <Flex justify="space-between" align="stretch">
          <Stack c="dimmed" fw={600} spacing={2}>
            <Text fw={700} fz="lg" color="black">
              {SAFETY_CONTROL_IDENTIFIER_PREFIX}-{safetyControl.identifier} {safetyControl.title}
            </Text>
            <Text>{formatDate(dayjs(safetyControl.createdAt), { date: { format: "long" }, time: true, includeTimezone: true })}</Text>
          </Stack>
          <Flex fw={700} direction="column" justify="space-between" align="flex-end" gap={6} mt={3}>
            <SafetyControlStatusDisplay status={safetyControl.status} justify="flex-end" size="sm" />
            <Box />
          </Flex>
        </Flex>
      </Paper>
    </UnstyledButton>
  )
}

export const SafetyControlResultCard = SafetyControlResultCardBase
