import { Box, Button, Center, Drawer, Loader, Popover, ScrollArea, Stack, TextInput, TextInputProps, useMantineTheme } from "@mantine/core"
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react"

import { useDisclosure, useId, useMediaQuery } from "@mantine/hooks"
import { UserContext } from "@soar/frontend/contexts"
import { useQuery } from "@tanstack/react-query"
import { SearchSelectClearButton } from "./clearButton"
import { InputDrawer } from "./drawer"
import { ResultsDisplay } from "./results"

export function SearchSelect<T extends { id: string | number | undefined }>({
  textInputProps = {},
  drawertextInputProps = {},
  onSelect = () => {},
  value,
  // initialValue,
  onQuery,
  renderResult,
  cacheKey,
  selectOnBlur = true,
  placeholder,
}: {
  value?: string
  // initialValue?: string
  cacheKey: string
  textInputProps?: Partial<TextInputProps>
  drawertextInputProps?: Partial<TextInputProps>
  onSelect?: (data: T | undefined) => void
  onQuery: (query: string, key: string) => Promise<T[]>
  renderResult: ({ data }: { data: T }) => ReactNode
  selectOnBlur?: boolean
  placeholder?: string
}) {
  const [_isPending, startTransition] = useTransition()
  const [searchValue, setSearchValue] = useState("")
  const [displayValue, setDisplayValue] = useState("")
  const [popoverState, popoverHandlers] = useDisclosure(false)
  const [drawerState, drawerHandlers] = useDisclosure(false)
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const [selectedData, setSelectedData] = useState<T>()
  const { searchKey } = useContext(UserContext)
  const drawerInputRef = useRef<HTMLInputElement>(null)

  const theme = useMantineTheme()
  const shouldUseDrawer = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  useEffect(() => {
    if (value != null) {
      setSearchValue(value)
      setDisplayValue(value)
    }
  }, [value])
  const searchValueIsValid = searchValue.trim().length > 0

  const { data, isLoading } = useQuery({
    queryKey: [cacheKey, searchValue],
    queryFn: () => onQuery(searchValue, searchKey?.key ?? ""),
    enabled: searchValueIsValid,
    retry: true,
    onError(e) {
      console.error("Search error: ", e)
    },
  })

  const results: T[] = data ?? []
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value)
    setSelectedIndex(undefined)
    if (e.target.value.length === 0) {
      popoverHandlers.close()
    }
    startTransition(() => {
      setSearchValue(e.target.value)
      openIfNeeded(e.target.value)
    })
  }
  const onChange = (value: string) => {
    setDisplayValue(value)
    startTransition(() => {
      setSearchValue(value)
    })
  }

  const handleSelect = (selectedData: T | undefined) => {
    if (selectedData != null) {
      // select element
      setDisplayValue("")
      popoverHandlers.close()
      setSelectedData(selectedData)
      onSelect(selectedData)
      startTransition(() => {
        setSearchValue("")
      })
    } else {
      popoverHandlers.close()
      onSelect(undefined)
    }
  }

  const onBlur = useCallback(() => {
    if (shouldUseDrawer) {
    } else {
      const results: T[] = data ?? []
      // popoverHandlers.close()

      if (results.length > 0 && selectOnBlur) {
        const firstResult = results.at(0)!
        handleSelect(firstResult)
        /*
        const capsSearchValue = searchValue.toUpperCase()
        if (firstResult.icao_id === capsSearchValue || firstResult.faa_airport_id === capsSearchValue) {
          handleSelect(firstResult)
        } else if (selectedAirport == null) {
          handleSelect(undefined)
        } else {
        }
        */
      }
    }
  }, [data, searchValue, selectOnBlur, shouldUseDrawer, selectedData])

  const openIfNeeded = (inputValue?: string) => {
    const value = inputValue ?? searchValue
    if (value.length > 0) {
      popoverHandlers.open()
    } else {
      popoverHandlers.close()
    }
  }
  const onFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (shouldUseDrawer) {
        drawerHandlers.open()

        // The following fakeInput stuff is to get around iOS focusing restrictions
        const fakeInput = document.createElement("input")
        fakeInput.setAttribute("type", "text")
        fakeInput.style.position = "absolute"
        fakeInput.style.opacity = "0"
        fakeInput.style.height = "0"
        fakeInput.style.fontSize = "16px"
        document.body.prepend(fakeInput)
        fakeInput.focus()

        setTimeout(() => {
          drawerInputRef.current?.focus()
          fakeInput.remove()
        }, 200)
      } else {
        openIfNeeded()
      }
    },
    [shouldUseDrawer],
  )

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp": {
        e.preventDefault()
        if (popoverState && results.length > 0 && (selectedIndex == null || selectedIndex > 0)) {
          setSelectedIndex((selectedIndex ?? -1) - 1)
        }
        // isColumn ? handlePrevious() : handleNext();
        break
      }

      case "ArrowDown": {
        e.preventDefault()
        if (popoverState && results.length > 0 && (selectedIndex == null || selectedIndex < results.length - 1)) {
          setSelectedIndex((selectedIndex ?? -1) + 1)
        }
        // isColumn ? handleNext() : handlePrevious();
        break
      }

      case "Enter": {
        if (popoverState && selectedIndex != null) {
          e.preventDefault()
          const airport = results[selectedIndex]
          handleSelect(airport)
        }
        break
      }

      case "Escape": {
        if (popoverState) {
          e.preventDefault()
          popoverHandlers.close()
        }
        break
      }
    }
  }
  const handleClear = () => {
    setDisplayValue("")
    setSearchValue("")
    handleSelect(undefined)
  }

  return (
    <>
      <Popover
        opened={popoverState}
        onClose={() => {
          // onBlur()
        }}
        transitionProps={{ duration: 150, transition: "fade" }}
        radius="lg"
        shadow="lg"
        middlewares={{ flip: false, shift: false, inline: false }}
        styles={(theme) => ({
          dropdown: {
            padding: "0",
            marginTop: "-8px",
            overflow: "hidden",
            height: "40vh",
            maxHeight: "40vh",
          },
        })}
        width="target"
        position="bottom"
        disabled={shouldUseDrawer}
      >
        <Popover.Target>
          <TextInput
            placeholder={placeholder}
            {...textInputProps}
            value={displayValue}
            onChange={onValueChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            rightSection={searchValueIsValid ? <SearchSelectClearButton onClick={handleClear} /> : undefined}
          />
        </Popover.Target>
        <Popover.Dropdown>
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <ResultsDisplay
              renderResult={renderResult}
              results={results}
              onSelect={(result) => {
                handleSelect(result)
              }}
              selectedIndex={selectedIndex}
            />
          )}
        </Popover.Dropdown>
      </Popover>
      <InputDrawer
        ref={drawerInputRef}
        value={displayValue}
        onClose={drawerHandlers.close}
        opened={drawerState}
        onChange={onChange}
        //@ts-ignore
        onSelect={onSelect}
        results={results}
        renderResult={renderResult}
        placeholder={placeholder}
        textInputProps={drawertextInputProps}
      />
    </>
  )
}
