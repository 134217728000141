import {
  DashboardTimespan,
  DashboardTimespanEnum,
  FlightLegStatus,
  FlightLegStatusEnum,
  SafetyControlStatus,
  SafetyControlStatusEnum,
  SafetyReportStatus,
  SafetyReportStatusEnum,
  TabsEnum,
  TabsType,
} from "@soar/shared/types"
import dayjs from "dayjs"
import { P, match } from "ts-pattern"

export function convertTimespanToDate(timespan: DashboardTimespan) {
  const dateOptions = generateDateOptions()
  return dateOptions[timespan].day
}

export function getTimespanLabel(timespan: DashboardTimespan) {
  const dateOptions = generateDateOptions()
  const dateOption = dateOptions[timespan]
  if (timespan === "all") {
    return dateOption.label
  } else if (timespan === "today") {
    return dateOption.day.format("MMM D")
  } else {
    return `${dateOption.day.format("MMM D")} - ${dayjs().format("MMM D")}`
  }
}

function generateDateOptions() {
  const day = dayjs()
  return {
    today: {
      day: day.startOf("day"),
      label: day.startOf("day").format("MMM D"),
    },
    week_1: {
      day: day.subtract(1, "week").startOf("day"),
      label: "1 week",
    },
    week_4: {
      day: day.subtract(4, "week").startOf("day"),
      label: "4 weeks",
    },
    mtd: {
      day: day.startOf("month"),
      label: "Month to date",
    },
    ytd: {
      day: day.startOf("year"),
      label: "Year to date",
    },
    all: {
      day: dayjs(new Date(0)),
      label: "All time",
    },
  }
}

export type DataPageParams = {
  flightLegStatusFilter: FlightLegStatus[]

  safetyReportStatusFilter: SafetyReportStatus[]
  safetyControlStatusFilter: SafetyControlStatus[]
  timespan: DashboardTimespan | undefined
  tab: TabsType | undefined
  organizationId: string | undefined
}
export function parseDataPageUrlParams(query: Record<string, string | string[] | undefined>): DataPageParams {
  const { status, dateFrom, timespan, id, tab, safetyReportStatus, safetyControlStatus } = query

  const newStatusFilter = match(status)
    .with(P.array(), (matchedValueArray) => {
      return matchedValueArray.filter((value): value is FlightLegStatus => {
        return FlightLegStatusEnum.safeParse(value).success
      })
    })
    .with(P.string, (matchedValue) => {
      if (FlightLegStatusEnum.safeParse(matchedValue).success) {
        return [matchedValue as FlightLegStatus]
      } else {
        return []
      }
    })
    .otherwise(() => [])

  const newSafetyReportStatus = match(safetyReportStatus)
    .with(P.array(), (matchedValueArray) => {
      return matchedValueArray.filter((value): value is SafetyReportStatus => {
        return SafetyReportStatusEnum.safeParse(value).success
      })
    })
    .with(P.string, (matchedValue) => {
      if (SafetyReportStatusEnum.safeParse(matchedValue).success) {
        return [matchedValue as SafetyReportStatus]
      } else {
        return []
      }
    })
    .otherwise(() => [])

  const newSafetyControlStatus = match(safetyControlStatus)
    .with(P.array(), (matchedValueArray) => {
      return matchedValueArray.filter((value): value is SafetyControlStatus => {
        return SafetyControlStatusEnum.safeParse(value).success
      })
    })
    .with(P.string, (matchedValue) => {
      if (SafetyControlStatusEnum.safeParse(matchedValue).success) {
        return [matchedValue as SafetyControlStatus]
      } else {
        return []
      }
    })
    .otherwise(() => [])

  const parsedTimespanResult = DashboardTimespanEnum.safeParse(timespan)
  const parsedTimespan = parsedTimespanResult.success ? parsedTimespanResult.data : DashboardTimespanEnum.enum.all

  const organizationId = typeof id === "string" ? id : undefined

  const parsedTabResult = TabsEnum.safeParse(tab)
  const parsedTab = parsedTabResult.success ? parsedTabResult.data : undefined

  return {
    flightLegStatusFilter: newStatusFilter,
    safetyReportStatusFilter: newSafetyReportStatus,
    safetyControlStatusFilter: newSafetyControlStatus,
    timespan: parsedTimespan,
    organizationId,
    tab: parsedTab,
  }
}
