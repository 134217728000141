import { Badge, Box, Button, Flex, Group, Select, Stack, Text, TextInput, Textarea, Title } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { BUTTON_GRADIENT_COLORS, HAZARD_CATEGORY_BADGE_PROPS, HazardCategoryForm, ModalOrDrawer } from "@soar/frontend/components"
import { organizationsForUserAtom } from "@soar/frontend/contexts"
import { trpc, trpcHttp } from "@soar/frontend/trpc-client"
import {
  Organization,
  SafetyControl,
  SafetyControlInput,
  SafetyControlInputSchema,
  SafetyControlSchema,
  SafetyReportFactorType,
} from "@soar/shared/types"
import { SafetyReportFactorConfig } from "@soar/shared/utils"
import { IconWand } from "@tabler/icons-react"
import { useAtomValue } from "jotai"
import { useEffect } from "react"

type SafetyControlInitialValues = {
  organizationId?: string
  factors?: SafetyReportFactorType[]
  safetyReportId?: string
}

export function SafetyControlModal({
  opened,
  onClose = () => {},
  onSuccess = () => {},
  values,
}: {
  opened: boolean
  values?: SafetyControlInitialValues
  onClose?: () => void
  onSuccess?: (safetyControl: SafetyControl) => void
}) {
  const { mutateAsync, isLoading } = trpc.safetyControl.createSafetyControl.useMutation()
  const { mutateAsync: generateSafetyControl, isLoading: isGenerating } = trpc.safetyControl.generateSafetyControl.useMutation()
  const organizations = useAtomValue(organizationsForUserAtom)

  const handleSubmit = async (safetyControl: SafetyControlInput) => {
    const safetyControlResponse = await mutateAsync({
      ...safetyControl,
    })
    const safetyControlParsed = SafetyControlSchema.parse(safetyControlResponse)
    onClose()
    showNotification({
      color: "green",
      message: "Risk control has been created!",
    })
    onSuccess(safetyControlParsed)
  }
  const handleGenerate = async (safetyReportId: string) => {
    return generateSafetyControl({ safetyReportId })
  }

  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      title={<Title order={3}>Add risk control</Title>}
      modalProps={{
        size: "lg",
      }}
    >
      <Box>
        <SafetyControlForm
          onGenerate={values?.safetyReportId != null ? handleGenerate : undefined}
          organizations={organizations ?? []}
          onSubmit={handleSubmit}
          isSubmitting={isLoading}
          isGenerating={isGenerating}
          values={values}
        />
      </Box>
    </ModalOrDrawer>
  )
}

const OptionalText = (
  <Text span fs="italic" fz="xs" c="dimmed">
    optional
  </Text>
)

function SafetyControlForm({
  onSubmit = () => {},
  onGenerate,
  organizations,
  isSubmitting,
  isGenerating,
  values,
}: {
  isSubmitting?: boolean
  isGenerating?: boolean
  onSubmit?: (safetyReport: SafetyControlInput) => void
  onGenerate?: (safetyReportId: string) => Promise<{ title: string; objective: string; implementationPlan: string }>
  organizations: Organization[]
  values?: SafetyControlInitialValues
}) {
  const form = useForm<SafetyControlInput>({
    initialValues: {
      organizationId: "",
      title: "",
      objective: "",
      implementationPlan: "",
      factors: [] as SafetyReportFactorType[],
    },
    validate: zodResolver(SafetyControlInputSchema),
  })
  const [hazardCategoryModalState, hazardCategoryModalHandlers] = useDisclosure(false)

  useEffect(() => {
    if (organizations != null && values?.organizationId == null) {
      form.setValues({
        organizationId: organizations[0].id,
      })
    }
  }, [organizations, values])

  useEffect(() => {
    if (values?.organizationId != null) {
      form.setValues({
        organizationId: values.organizationId,
      })
    }

    if (values?.factors != null) {
      form.setValues({
        factors: values.factors,
      })
    }
  }, [values])

  const handleGenerate = async () => {
    if (values?.safetyReportId != null && onGenerate != null) {
      const response = await onGenerate(values.safetyReportId)
      form.setValues({
        ...response,
      })
    }
  }

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(async (values) => {
        onSubmit(values)
      })}
    >
      <Stack>
        <Box>
          <Text fz="sm" fw={500}>
            Hazards mitigated {OptionalText}
          </Text>
          <Flex justify="space-between" align="center">
            {form.values.factors.length > 0 ? (
              <Group style={{ flexGrow: 1 }} spacing="xs">
                {form.values.factors.map((factor) => {
                  const config = SafetyReportFactorConfig[factor]
                  return (
                    <Badge {...HAZARD_CATEGORY_BADGE_PROPS} size="xs" key={factor}>
                      {config.label}
                    </Badge>
                  )
                })}
              </Group>
            ) : (
              <Text color="dimmed" fz="xs">
                No hazards selected...
              </Text>
            )}
            <Button onClick={hazardCategoryModalHandlers.open} variant="subtle">
              Edit
            </Button>
          </Flex>
        </Box>
        <TextInput label="Title" placeholder="Summarize the control in one line" {...form.getInputProps("title")} />

        <TextInput label="Objective" placeholder="Describe the purpose of this control" {...form.getInputProps("objective")} />

        <Textarea
          label={<Text>Implementation plan {OptionalText}</Text>}
          placeholder="Outline high-level steps..."
          minRows={5}
          {...form.getInputProps("implementationPlan")}
        />

        {values?.safetyReportId != null && (
          <Flex justify="flex-end">
            <Button
              onClick={handleGenerate}
              variant="gradient"
              gradient={{ from: "violet.9", to: "#F15A29", deg: 155 }}
              size="xs"
              loading={isGenerating}
              leftIcon={<IconWand size={14} />}
              color="violet.8"
            >
              Generate with AI
            </Button>
          </Flex>
        )}

        {values?.organizationId == null && (
          <Select
            label="Organization"
            data={organizations?.map((org) => ({ value: org.id, label: org.name })) ?? []}
            {...form.getInputProps("organizationId")}
            style={{ order: 30 }}
            disabled={values?.organizationId != null}
          />
        )}

        <Button
          variant="gradient"
          gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }}
          type="submit"
          style={{ order: 50 }}
          loading={isSubmitting}
        >
          Create control
        </Button>
      </Stack>

      <ModalOrDrawer
        opened={hazardCategoryModalState}
        onClose={hazardCategoryModalHandlers.close}
        title={<Title order={3}>Edit hazard categories</Title>}
        drawerProps={{
          styles: {
            body: {
              height: "calc(97cqh - 75px)",
            },
          },
        }}
        modalProps={{
          size: "xl",
          styles: {
            body: {
              height: "80cqh",
            },
          },
        }}
      >
        <HazardCategoryForm
          value={form.values.factors}
          onSave={(categories) => {
            form.setFieldValue("factors", categories)
            hazardCategoryModalHandlers.close()
          }}
        />
      </ModalOrDrawer>
    </Box>
  )
}
